import { Box as MuiBox, Grid } from "@mui/material";
import SideMenu from "../SideMenu/SideMenu";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { UPDATE } from "../Sidebar/Sidebar";
import store from "../../Services/store";

const ContentLayout = () => {
  const dispatch = useDispatch();

  const sidebarIsOpen = store.getState().sidebar.isOpen;

  useEffect(() => {
    dispatch({
      type: UPDATE,
      payload: !store.getState().sidebar.isOpen,
    });
  }, []);

  return (
    <Grid container sx={{ width: "auto" }}>
      <Grid item xs={3}>
        <SideMenu />
      </Grid>
      <Grid item xs={9}>
        <MuiBox>
          <Outlet />
        </MuiBox>
      </Grid>
    </Grid>
  );
};

export default ContentLayout;
