import { createAsyncThunk } from "@reduxjs/toolkit";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";
import { CreateOrEditLanguageModel } from "../../../Models/Core/Languages/CreateOrEditLanguageModel";

export const LanguagesGetAllAction = createAsyncThunk(
  "LanguagesGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Languages", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const LanguagesGetAction = createAsyncThunk(
  "LanguagesGetAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/Languages/" + id,
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const LanguagesCreateAction = createAsyncThunk(
  "LanguagesCreateAction",
  async (data: CreateOrEditLanguageModel, { rejectWithValue }) => {
    return await callToBackend(
      "api/Languages",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return { success: res?.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error?.response?.data?.detail });
      });
  }
);

export const LanguagesUpdateAction = createAsyncThunk(
  "LanguagesUpdateAction",
  async (data: any) => {
    return await callToBackend(
      "api/Languages",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return { success: res.statusText };
      })
      .catch((error) => {
        return { error: error };
      });
  }
);

export const LanguagesDeleteAction = createAsyncThunk(
  "LanguagesDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Languages/" + id,
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return { success: res.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
