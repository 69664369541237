import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ThreeDModelsCreateAction,
  ThreeDModelsDeleteAction,
  ThreeDModelsGetAllAction,
  ThreeDModelsGetListByDynamicAction,
  ThreeDModelsUpdateAction,
} from "../../../Actions/Customer/ThreeDModels/ThreeDModelsActions";
import { IThreeDModelsState } from "./interface";
import { Paginations } from "../../../DTOs/Paginations/paginations.dto";

const initialState: IThreeDModelsState = {
  threeDModels: [],
  count: 0,
  successMessage: "",
  errorMessage: "",
  isDataLoaded: false,
  pagination: new Paginations(),
};

const ThreeDModelsSlice = createSlice({
  name: "ThreeDModelsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ThreeDModelsGetAllAction.pending, (state, action) => {
      state.threeDModels = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ThreeDModelsGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pagination = new Paginations(
          action.payload.index,
          action.payload.size
        );
        state.isDataLoaded = true;
        state.dynamicAction = "withoutDynamic";
        state.count = action.payload.count;
        state.threeDModels = action.payload.items;
      }
    );
    builder.addCase(
      ThreeDModelsGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(
      ThreeDModelsGetListByDynamicAction.pending,
      (state, action) => {
        state.threeDModels = [];
        state.successMessage = "";
        state.errorMessage = "";
      }
    );
    builder.addCase(
      ThreeDModelsGetListByDynamicAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pagination = new Paginations(
          action.payload.index,
          action.payload.size
        );
        state.isDataLoaded = true;
        state.dynamicAction = "withDynamic";
        state.count = action.payload.count;
        state.threeDModels = action.payload.items;
      }
    );
    builder.addCase(
      ThreeDModelsGetListByDynamicAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ThreeDModelsCreateAction.pending, (state, action) => {
      state.threeDModels = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ThreeDModelsCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ThreeDModelsCreateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ThreeDModelsUpdateAction.pending, (state, action) => {});
    builder.addCase(
      ThreeDModelsUpdateAction.fulfilled,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(
      ThreeDModelsUpdateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ThreeDModelsDeleteAction.pending, (state, action) => {
      state.threeDModels = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ThreeDModelsDeleteAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ThreeDModelsDeleteAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
  },
});

export default ThreeDModelsSlice.reducer;
