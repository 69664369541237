export class CreateOrEditThreeDModelModel {
  id: number | undefined;
  path: string | undefined;
  name: string | undefined;
  extension: string | undefined;
  productId: number | undefined;
  productModel:
    | {
        id: number;
        productId: number;
        modelId: number;
      }
    | undefined;
}
