import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UserEditionInformationsGetAllAction,
  UserEditionInformationsGetByUserIdAction,
  UserEditionInformationsGetFromAuthAction,
  UserEditionInformationsGetNewAmountAction,
} from "../../../Actions/Shared/UserEditionInformations/UserEditionInformationsActions";
import { IUserEditionInformationsState } from "./interface";
import {
  UserEditionInformations,
  UserEditionInformationsGetNewAmount,
} from "../../../DTOs/UserEditionInformations/userEditionInformations.dto";

const initialState: IUserEditionInformationsState = {
  userEditionInformations: [],
  userEditionInformation: new UserEditionInformations(),
  newAmount: new UserEditionInformationsGetNewAmount(),
  count: 0,
  successMessage: "",
  errorMessage: "",
  isDataLoaded: false,
  loading: false,
};

const UserEditionInformationSlice = createSlice({
  name: "UserEditionInformationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      UserEditionInformationsGetAllAction.pending,
      (state, action) => {
        state.userEditionInformations = [];
        state.successMessage = "";
        state.errorMessage = "";
        state.loading = true;
      }
    );
    builder.addCase(
      UserEditionInformationsGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.userEditionInformations = action.payload.items;
        state.count = action.payload.count;
        state.loading = false;
      }
    );
    builder.addCase(
      UserEditionInformationsGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.loading = false;
      }
    );
    builder.addCase(
      UserEditionInformationsGetFromAuthAction.pending,
      (state, action) => {
        state.successMessage = "";
        state.errorMessage = "";
        state.loading = true;
      }
    );
    builder.addCase(
      UserEditionInformationsGetFromAuthAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.userEditionInformation = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      UserEditionInformationsGetFromAuthAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.loading = false;
      }
    );
    builder.addCase(
      UserEditionInformationsGetByUserIdAction.pending,
      (state, action) => {
        state.successMessage = "";
        state.errorMessage = "";
        state.loading = true;
      }
    );
    builder.addCase(
      UserEditionInformationsGetByUserIdAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.userEditionInformation = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      UserEditionInformationsGetByUserIdAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.loading = false;
      }
    );
    builder.addCase(
      UserEditionInformationsGetNewAmountAction.pending,
      (state, action) => {
        state.successMessage = "";
        state.errorMessage = "";
        state.loading = true;
      }
    );
    builder.addCase(
      UserEditionInformationsGetNewAmountAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.newAmount = action.payload.priceToBePaid;
        state.loading = false;
      }
    );
    builder.addCase(
      UserEditionInformationsGetNewAmountAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.loading = false;
      }
    );
  },
});

export default UserEditionInformationSlice.reducer;
