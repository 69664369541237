import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";
import { CreateOrEditThreeDModelModel } from "../../../Models/Core/ThreeDModels/CreateOrEditThreeDModelModel";

export const ThreeDModelsGetAllAction = createAsyncThunk(
  "ThreeDModelsGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Models", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ThreeDModelsGetListByDynamicAction = createAsyncThunk(
  "ThreeDModelsGetListByDynamicAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Models/GetList/ByDynamic", data.pagination),
      "POST",
      undefined,
      data.query,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ThreeDModelsGetAction = createAsyncThunk(
  "ThreeDModelsGetAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/Models/" + id,
      "GET",
      CreateOrEditThreeDModelModel,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ThreeDModelsCreateAction = createAsyncThunk(
  "ThreeDModelsCreateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Models",
      "POST",
      undefined,
      data,
      getToken(),
      true
    )
      .then((res) => {
        return { success: res?.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ThreeDModelsUpdateAction = createAsyncThunk(
  "ThreeDModelsUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend("api/Models", "PUT", undefined, data, getToken())
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ThreeDModelsDeleteAction = createAsyncThunk(
  "ThreeDModelsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Models/" + id,
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return { success: res?.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
