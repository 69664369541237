import * as React from "react";
import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import HStack from "../HStack/HStack";
import Button from "../Button/Button";
import Modal from "../../../Layouts/Modal/Modal";

interface IWarningCardProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content?: string;
}

const WarningCard: FC<IWarningCardProps> = ({
  open,
  onClose,
  onConfirm,
  content,
}) => {
  return (
    <Modal
      open={open}
      customGridVal={{ xs: 12, sm: 10, md: 10, lg: 3, xl: 3 }}
      onClose={onClose}
      children={
        <Grid container spacing={6} textAlign="center">
          <Grid item xs={12}>
            <Typography>
              {content ? content : "AreYouSureYouWantToDelete"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <HStack justifyContent="space-around" spacing="48px">
              <Button label="Kapat" size="medium" onClick={onClose} />
              <Button
                label="Evet"
                size="medium"
                variant="contained"
                onClick={() => {
                  onClose();
                  onConfirm();
                }}
              />
            </HStack>
          </Grid>
        </Grid>
      }
    />
  );
};
export default WarningCard;
