import { Box, TextField } from "@mui/material";
import { ChangeEvent, FC, ReactNode } from "react";
import { Color } from "../Color/Color";

export interface TextInputProps {
  id: string;
  label: string;
  type?: string;
  value?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  regex?: any;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  onKeyUp?: any;
  onBlur?: any;
  onFocus?: any;
  autoComplete?: string;
}

export const TextInput: FC<TextInputProps> = ({
  id,
  label,
  type = "text",
  value = "",
  onChange,
  required = false,
  disabled = false,
  min,
  max,
  regex,
  startAdornment,
  endAdornment,
  placeholder,
  helperText,
  error,
  onKeyUp,
  onBlur,
  onFocus,
  autoComplete = "off",
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        id={id}
        label={label === undefined ? "" : label!}
        placeholder={placeholder === undefined ? "" : placeholder!}
        type={type}
        value={value}
        onChange={handleChange}
        variant="outlined"
        required={required}
        disabled={disabled}
        size="medium"
        fullWidth={true}
        error={error}
        helperText={helperText}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete={autoComplete}
        inputProps={{
          startadornment: startAdornment,
          endadornment: endAdornment,
          minLength: min,
          maxLength: max,
          pattern: regex,
        }}
        InputProps={{
          style: {
            borderRadius: "18px",
            backgroundColor: "white",
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: "700",
            fontFamily: "Nunito Sans",
          },
          startAdornment: startAdornment,
          endAdornment: endAdornment,
        }}
        sx={{
          "& label.Mui-focused": {
            color: Color.Blue3,
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: Color.Blue3,
            },
          },
          "& .MuiFormLabel-asterisk": {
            color: "red",
          },
        }}
      />
    </Box>
  );
};

export default TextInput;
