import { createAsyncThunk } from "@reduxjs/toolkit";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";

export const ProductArButtonsGetAllAction = createAsyncThunk(
  "ProductArButtonsGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/ProductArButtons", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductArButtonsCreateAction = createAsyncThunk(
  "ProductArButtonsCreateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/ProductArButtons",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return { success: res.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductArButtonsUpdateAction = createAsyncThunk(
  "ProductArButtonsUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/ProductArButtons",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductArButtonsDeleteAction = createAsyncThunk(
  "ProductArButtonsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/ProductArButtons", id),
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
