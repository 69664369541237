import { IProductModels } from "../../Interfaces/ProductModels/productModels.interface";
import { IProductUrlNames } from "../../Interfaces/ProductUrlNames/productUrlNames.interface";
import {
  ICreateOrEditProduct,
  IProducts,
} from "../../Interfaces/Products/products.interface";
import { IProductsArButtons } from "../../Interfaces/ProductsArButtons/productsArButtons.interface";
import { IProductsQrButtons } from "../../Interfaces/ProductsQrButtons/productsQrButtons.interface";
import { ProductArButtons } from "../ProductArButtons/productArButtons.dto";
import { ProductQrButtons } from "../ProductQrButtons/productQrButtons.dto";
import { ProductUrlNames } from "../ProductUrlNames/productUrlNames.dto";

//GetAll
export class Products implements IProducts {
  id: number | undefined;
  name: string;
  productModelId: number | undefined;
  productModelModelName: string;
  userProfileId: number | undefined;

  constructor(
    id: number | undefined = undefined,
    name: string = "",
    productModelId: number | undefined = undefined,
    productModelModelName: string = "",
    userProfileId: number | undefined = undefined
  ) {
    this.id = id;
    this.name = name;
    this.productModelId = productModelId;
    this.productModelModelName = productModelModelName;
    this.userProfileId = userProfileId;
  }
}

//CreateOrEditProduct
export class CreateOrEditProduct implements ICreateOrEditProduct {
  id: number | undefined;
  name: string;
  modelId: number | undefined;
  productArButtons: IProductsArButtons[];
  productQrButtons: IProductsQrButtons[];
  productModel: IProductModels;
  productModelId?: number | undefined;
  productUrlNames: IProductUrlNames[];
  userProfileId?: number | undefined;

  constructor(
    id: number | undefined = undefined,
    name: string = "",
    productArButtons: IProductsArButtons[] = [],
    productQrButtons: IProductsQrButtons[] = [],
    productModel: IProductModels,
    productModelId: number | undefined = undefined,
    productUrlNames: IProductUrlNames[],
    userProfileId: number | undefined = undefined
  ) {
    this.id = id;
    this.name = name;
    this.productArButtons = productArButtons;
    this.productQrButtons = productQrButtons;
    this.productModel = productModel;
    this.productModelId = productModelId;
    this.productUrlNames = productUrlNames;
    this.userProfileId = userProfileId;
  }
}

export class CreateOrEditProductForUI {
  id: number | undefined;
  productName: string;
  productModelId?: number;
  modelId?: number;
  product: any[] | undefined;

  constructor(
    productName: string = "",
    product = [
      {
        productUrlNames: new ProductUrlNames(),
        productQrButtons: new ProductQrButtons(),
        productArButtons: new ProductArButtons(),
        languageId: undefined,
      },
    ]
  ) {
    this.productName = productName;
    this.product = product;
  }
}
