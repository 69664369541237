import React, { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import "./Center.css";

export interface CenterProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  width?: string;
  height?: string;
  bg?: string;
  borderRadius?: string;
}

const CenterComponent = styled.div<{
  width: string;
  height: string;
  bg: string;
  borderRadius: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
  border-radius: ${(props) => props.borderRadius};
`;

export const Center: FC<CenterProps> = ({
  children,
  className,
  width = "100%",
  height = "100%",
  bg = "none",
  borderRadius = "0px",
  ...props
}) => {
  return (
    <CenterComponent
      width={width}
      height={height}
      bg={bg}
      borderRadius={borderRadius}
      className={`tritophia-center${
        className !== undefined ? " " + className : ""
      }`}
      {...props}
    >
      {children}
    </CenterComponent>
  );
};

export default Center;
