const remoteServiceBaseUrl = "https://api.tritophia.com/";
// const remoteServiceBaseUrl = "https://apptest.tritophia.com/";
// const remoteServiceBaseUrl = "https://localhost:7066/";
const appBaseUrl = "https://api.com/";
const SOURCE_NAME = "Tritophia";
const DEFAULT_LANGUAGE = "tr";
const REMOTE_SERVICE_BASE_URL = "";
const APP_BASE_HREF = "";

export {
  APP_BASE_HREF,
  DEFAULT_LANGUAGE,
  REMOTE_SERVICE_BASE_URL,
  SOURCE_NAME,
  appBaseUrl,
  remoteServiceBaseUrl,
};
