import { createAsyncThunk } from "@reduxjs/toolkit";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";

export const DashboardsGetCustomerDashboardInformationAction = createAsyncThunk(
  "DashboardsGetCustomerDashboardInformationAction",
  async (id: number | undefined, { rejectWithValue }) => {
    return await callToBackend(
      id
        ? encodeUrl("api/Dashboards/GetCustomerDashboardInformation", {
            userId: id,
          })
        : "api/Dashboards/GetCustomerDashboardInformation",
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
