import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";
import { CreateOrEditPaymentModel } from "../../../Models/Core/Admin/Payments/CreateOrEditPaymentModel";

export const PaymentsGetAllAction = createAsyncThunk(
  "PaymentsGetAllAction",
  async (_, { rejectWithValue }) => {
    return await callToBackend(
      "api/Payments",
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const PaymentsGetAction = createAsyncThunk(
  "PaymentsGetAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/Payments/" + id,
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const PaymentsGetFromAuthAction = createAsyncThunk(
  "PaymentsGetFromAuthAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Payments/GetFromAuth", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const PaymentsGetListByUserIdAction = createAsyncThunk(
  "PaymentsGetListByUserIdAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Payments/GetListByUserId", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const PaymentsCreateAction = createAsyncThunk(
  "PaymentsCreateAction",
  async (data: CreateOrEditPaymentModel, { rejectWithValue }) => {
    return await callToBackend(
      "api/Payments",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res?.data?.data.id;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const PaymentsUpdateAction = createAsyncThunk(
  "PaymentsUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Payments",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const PaymentsDeleteAction = createAsyncThunk(
  "PaymentsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Payments", id),
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
