import { SideAppMenuItem } from "./side-app-menu-item";

export class SideAppMenu {
  name = "";
  displayName = "";
  items: SideAppMenuItem[];

  constructor(name: string, displayName: string, items: SideAppMenuItem[]) {
    this.name = name;
    this.displayName = displayName;
    this.items = items;
  }
}
