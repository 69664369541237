export const EncodeUrl = (url: string, data: any) => {
  var newUrl = url + "?";

  Object.keys(data).forEach(function (key, index) {
    if (data[key] !== undefined && typeof data[key] !== "object") {
      newUrl += key + "=" + data[key] + "&";
    } else if (typeof data[key] === "object") {
      if (data[key] instanceof Date) {
        var formattedDate = data[key].toISOString();
        newUrl += key + "=" + formattedDate + "&";
      } else {
        Object.values(data[key]).map((element) => {
          newUrl += key + "=" + element + "&";
        });
      }
    }
  });

  if (newUrl.slice(-1) === "&") {
    newUrl = newUrl.substring(0, newUrl.length - 1);
  }

  return newUrl;
};
