import { UserType } from "../../Services/Models/Enums/UserType";

export class AppMenuItem {
  name = "";
  icon: JSX.Element = (<svg></svg>);
  route = "";
  items: AppMenuItem[];
  permission = "";
  active?: boolean = false;
  userType?: UserType = 1;

  constructor(
    name: string,
    icon: JSX.Element,
    route: string,
    items?: AppMenuItem[],
    permission?: string,
    userType?: UserType
  ) {
    this.name = name;
    this.icon = icon;
    this.route = route;
    this.route = route;
    this.userType = userType;

    if (items === undefined) {
      this.items = [];
    } else {
      this.items = items;
    }

    if (permission === undefined) {
      this.permission = "";
    } else {
      this.permission = permission;
    }
  }
}
