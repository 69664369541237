import React, { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import "./HStack.css";
import { SxProps, Theme } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";

export interface HStackProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  spacing?: string;
  width?: string;
  height?: string;
  bg?: string;
  borderRadius?: string;
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  sx?: SxProps<Theme> | undefined;
}

const HStackComponent = styled.div<HStackProps>`
  gap: ${(props) => props.spacing};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
  border-radius: ${(props) => props.borderRadius};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

export const TritophiaHStack: FC<HStackProps> = ({
  children,
  className,
  spacing = "0px",
  width = "100%",
  height = "100%",
  bg = "none",
  borderRadius = "0px",
  justifyContent = "flex-start",
  alignItems = "center",
  sx,
  ...props
}) => {
  return (
    <HStackComponent
      spacing={spacing}
      width={width}
      height={height}
      bg={bg}
      borderRadius={borderRadius}
      justifyContent={justifyContent}
      className={`tritophia-hstack${
        className !== undefined ? " " + className : ""
      }`}
      alignItems={alignItems}
      sx={sx}
      {...props}
    >
      {children}
    </HStackComponent>
  );
};

const HStack = MuiStyled(TritophiaHStack)();

export default HStack;
