import VStack from "../../Components/Shared/VStack/VStack";
import HStack from "../../Components/Shared/HStack/HStack";
import { Divider, IconButton, Skeleton, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "./SideMenu.module.css";
import { SideMenuAppNavigationService } from "./SideMenuAppNavigationService";
import { SideAppMenuItem } from "./side-app-menu-item";
import { Color } from "../../Components/Shared/Color/Color";
import Center from "../../Components/Shared/Center/Center";
import { useAppDispatch } from "../../Services/store";
import { CustomersGetAction } from "../../Services/Actions/Shared/Customers/CustomersActions";
import TritophiaTypography from "../../Components/Shared/TritophiaTypography/TritophiaTypography";

const SideMenu = () => {
  const location = useLocation();
  const menuService = new SideMenuAppNavigationService();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [menu, setMenu] = useState(menuService.getMenu());
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (id) {
      setLoading(true);

      dispatch(CustomersGetAction(Number(id)))
        .unwrap()
        .then((res) => setCompanyName(res?.companyName))
        .finally(() => setLoading(false));
    }
  }, []);

  const isButtonActive = (item: SideAppMenuItem): boolean => {
    let urlString = location.pathname + location.search;
    let route = item.route;

    if (id) {
      route = route.replace(":id", id);
    }

    let exactMatch = urlString === route;

    return exactMatch;
  };

  const changeIconActive = (item: SideAppMenuItem): JSX.Element => {
    let isActiveMenu = isButtonActive(item);
    if (!isActiveMenu) {
      return item.icon;
    }

    let result: JSX.Element | void;

    for (let index = 0; index < menu.items.length; index++) {
      const menuItem = menu.items[index];

      if (menuItem === item) {
        result = menuService.getMenuIcon(index, "#3363FF");
        break;
      }
    }

    if (result! === undefined) {
      return item.icon;
    }

    return result;
  };

  const isOpenMenuItem = (item: SideAppMenuItem) => {
    var test = menu;
    let currentChangeMenu = test.items.find((x: any) => x.name === item.name);
    currentChangeMenu!.active = !currentChangeMenu!.active;

    setMenu({ ...menu });
  };

  const adjustedRoute = (menuItem: any) => {
    if (id) {
      navigate(menuItem.route.replace(":id", id));
    } else {
      navigate("/admin/customers");
    }
  };

  return (
    <VStack
      width="auto"
      height="700px"
      bg={Color.Gray12}
      borderRadius="32px"
      style={{
        padding: "10px 64px",
        margin: "32px 32px 32px 0px",
        boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.25)",
        borderRadius: "16px",
        overflowY: "auto",
      }}
      spacing="10px"
    >
      <VStack
        height="auto"
        justifyContent="center"
        alignItems="center"
        sx={{
          pb: 2,
          pt: 2,
        }}
      >
        <TritophiaTypography variant="h4" isLoading={loading}>
          {companyName}
        </TritophiaTypography>
      </VStack>

      <VStack spacing="6px" height="auto" style={{ flex: 1 }}>
        {menu.items.map((menuItem: any, index: any) => (
          <VStack height="auto" width="100%">
            <HStack
              key={index}
              className={styles.sidebarItemContainer}
              height="24px"
              spacing="16px"
              width="-webkit-fill-available"
              style={{
                padding: "8px",
                paddingInlineStart: "16px",
                cursor: "pointer",
                background:
                  isButtonActive(menuItem) ||
                  menu.items.find((x) => menuItem.active)
                    ? "#8f52eb44"
                    : "",
              }}
              borderRadius="16px"
              onClick={() => adjustedRoute(menuItem)}
            >
              <Center width="16px" height="16px" style={{ minWidth: "16px" }}>
                <>{changeIconActive(menuItem)}</>
              </Center>
              <HStack justifyContent="space-between">
                {menuItem.items.length > 0 ? (
                  <HStack>
                    <p
                      style={{
                        textDecoration: "none",
                        color: "rgb(29, 25, 41)",
                        fontSize: "14px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      {menuItem.name}
                    </p>
                  </HStack>
                ) : (
                  <p
                    style={{
                      textDecoration: "none",
                      color: "#1D1929",
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "100%",
                      userSelect: "none",
                    }}
                  >
                    {menuItem.name}
                  </p>
                )}

                {menuItem.items.length > 0 ? (
                  <IconButton
                    sx={{ alignSelf: "center" }}
                    onClick={() => isOpenMenuItem(menuItem)}
                  >
                    {menuItem.active ? (
                      <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 9.5L7 6.5L4 9.5"
                          stroke="#1D1929"
                          stroke-linecap="square"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 6.5L7 9.5L10 6.5"
                          stroke="#1D1929"
                          stroke-linecap="square"
                        />
                      </svg>
                    )}
                  </IconButton>
                ) : null}
              </HStack>
            </HStack>
            {menuItem.items.length > 0 && menuItem.active && (
              <VStack
                height="auto"
                spacing="16px"
                width="auto"
                style={{
                  paddingBlockStart: "8px",
                  paddingInlineStart: "48px",
                }}
              >
                {menuItem.items.map((subMenu: any, index: any) => (
                  <Link
                    to={subMenu.route}
                    className={styles.sidebarIconBtn}
                    style={{
                      textDecoration: "none",
                      color: isButtonActive(subMenu) ? "#8A24EA" : "#1D1929",
                      fontSize: "14px",
                    }}
                  >
                    {subMenu.name}
                  </Link>
                ))}
              </VStack>
            )}
          </VStack>
        ))}
      </VStack>
    </VStack>
  );
};

export default SideMenu;
