import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const getCurrentLanguage = () => {
  const cookieLang = cookies.get("language");
  if (cookieLang) {
    return cookieLang;
  }

  return navigator.language === "en-US" ? "en" : "tr";
};
