import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import SidebarReducer from "./Reducers/SidebarReducer";
import EditionsSlice from "./Slices/Admin/Editions/EditionsSlice";
import LanguagesSlice from "./Slices/Customer/Languages/LanguagesSlice";
import ProductArButtonsSlice from "./Slices/Customer/ProductArButtons/ProductArButtonsSlice";
import ProductQrButtonsSlice from "./Slices/Customer/ProductQrButtons/ProductQrButtonsSlice";
import ProductUrlNamesSlice from "./Slices/Customer/ProductUrlNames/ProductUrlNamesSlice";
import ProductsSlice from "./Slices/Customer/Products/ProductsSlice";
import CustomersSlice from "./Slices/Shared/Customers/CustomersSlice";
import IntegrationsSlice from "./Slices/Shared/Integrations/IntegrationsSlice";
import PaymentsSlice from "./Slices/Shared/Payments/PaymentsSlice";
import UserEditionInformationSlice from "./Slices/Shared/UserEditionInformation/UserEditionInformationSlice";
import CustomerDashboardsSlice from "./Slices/Customer/Dashboards/DashboardsSlice";
import AdminDashboardsSlice from "./Slices/Admin/Dashboards/DashboardsSlice";
import ThreeDModelsSlice from "./Slices/Customer/ThreeDModels/ThreeDModelsSlice";

const appReducer = combineReducers({
  sidebar: SidebarReducer,
  products: ProductsSlice,
  threeDModels: ThreeDModelsSlice,
  productUrlNames: ProductUrlNamesSlice,
  productArButtons: ProductArButtonsSlice,
  productQrButtons: ProductQrButtonsSlice,
  languages: LanguagesSlice,
  customers: CustomersSlice,
  integrations: IntegrationsSlice,
  editions: EditionsSlice,
  payments: PaymentsSlice,
  userEditionInformations: UserEditionInformationSlice,
  customerDashboards: CustomerDashboardsSlice,
  adminDashboards: AdminDashboardsSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: false,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
