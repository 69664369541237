import { IPaginations } from "../../Interfaces/Paginations/paginations.interface";

export class Paginations implements IPaginations {
  pageIndex: number;
  pageSize: number;

  constructor(pageIndex: number = 0, pageSize: number = 10) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }
}
