import { Suspense, lazy } from "react";
import { createPortal } from "react-dom";
import ReactDOM from "react-dom/client";
import PreviewThreeDModel from "../Components/App/Customer/ThreeDModels/PreviewThreeDModel/PreviewThreeDModel";
import WarningCard from "../Components/Shared/WarningCard/WarningCard";
import { LanguagesGetAllAction } from "../Services/Actions/Customer/Languages/LanguagesActions";
import { ProductsGetListByDynamicAction } from "../Services/Actions/Customer/Products/ProductsActions";
import { CreateOrEditEditionModel } from "../Services/Models/Core/Admin/Editions/CreateOrEditEditionModel";
import { GetAllModel } from "../Services/Models/Core/GetAllModel";
import { CreateOrEditLanguageModel } from "../Services/Models/Core/Languages/CreateOrEditLanguageModel";
import { CreateOrEditThreeDModelModel } from "../Services/Models/Core/ThreeDModels/CreateOrEditThreeDModelModel";
import store from "../Services/store";
import Alert, { AlertType } from "../Utils/AlertUtil";
import Loadable from "../Utils/Loadable";
import { checkStoreBeforeDispatching } from "../Helpers/StoreHelper";
import { ThreeDModelsGetListByDynamicAction } from "../Services/Actions/Customer/ThreeDModels/ThreeDModelsActions";
import { Languages } from "../Services/DTOs/Languages/languages.dto";
import { CreateOrEditProductForUI } from "../Services/DTOs/Products/products.dto";
import { EditionsGetAllAction } from "../Services/Actions/Admin/Editions/EditionsActions";

//#region Actions

//#endregion

//#region Models

//#endregion

//#region Modals

const Loading = Loadable(lazy(() => import("../Utils/Loading")));

const CreateOrEditThreeDModelModal = Loadable(
  lazy(
    () =>
      import(
        "../Components/App/Customer/ThreeDModels/CreateOrEditThreeDModelModal"
      )
  )
);

const CreateOrEditProductModal = Loadable(
  lazy(
    () => import("../Components/App/Customer/Products/CreateOrEditProductModal")
  )
);

const CreateOrEditSiteLanguageModal = Loadable(
  lazy(
    () =>
      import(
        "../Components/App/Customer/Integration/SiteLanguages/CreateOrEditSiteLanguageModal"
      )
  )
);

//Admin

const CreateOrEditEditionModal = Loadable(
  lazy(
    () => import("../Components/App/Admin/Editions/CreateOrEditEditionModal")
  )
);

const NewCustomerRequestModal = Loadable(
  lazy(
    () => import("../Components/App/Admin/Customers/NewCustomerRequestModal")
  )
);

const CreateOrEditPaymentHistoryModal = Loadable(
  lazy(
    () =>
      import(
        "../Components/App/Admin/Customers/PaymentHistory/CreateOrEditPaymentHistoryModal"
      )
  )
);

const UpgrageEditionModal = Loadable(
  lazy(
    () =>
      import(
        "../Components/App/Admin/Customers/Detail/Edition/UpgradeEditionModal"
      )
  )
);

// const PreviewThreeDModel = Loadable(
//   lazy(
//     () =>
//       import(
//         "../Components/App/Customer/ThreeDModels/PreviewThreeDModel/PreviewThreeDModel"
//       )
//   )
// );
//#endregion

// interface ICreateOrEditModal<T extends EntityModel | undefined> {
//   createOrEditEntity?: T;
//   handleReset: () => void;
// }

interface IExampleModal {
  handleReset: () => void;
}

interface IAlert {
  type: AlertType;
  content?: string;
}
interface ILoading {
  isLoading: boolean;
}

interface IWarningCard {
  onConfirm: () => void;
  content?: string;
}

interface ICreateOrEditThreeDModelModal {}

interface ICreateOrEditProductModal {
  title: string;
  product: CreateOrEditProductForUI;
  productId: number | undefined;
}

interface IPreviewThreeDModel {
  threeDModel: string;
}

interface ICreateOrEditThreeDModelModal {
  title: string;
  createOrEditThreeDModel: CreateOrEditThreeDModelModel;
}

interface ICreateOrEditEditionModelModal {
  createOrEditEdition: CreateOrEditEditionModel;
}

interface ICreateOrEditPaymentHistoryModal {
  editions: any;
  createOrEditPaymentHistory: any;
  userProfileId: number;
}

//#endregion

export const Roc = {
  alert: async function ({ type, content }: IAlert) {
    await Rocket.alertLaunch(
      <Alert
        open={true}
        type={type}
        content={content}
        handleClose={async () => await Rocket.alertEnd()}
      />
    );
  },

  // loading: async function fire({ isLoading }: ILoading) {
  //   if (isLoading) {
  //     loadingModalId = await Rocket.launch(<Loading />);
  //   } else {
  //     Rocket.end(loadingModalId);
  //     loadingModalId = "";
  //   }
  // },

  loading: {
    open: async function open() {
      loadingModalId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <Loading />
        </Suspense>
      );
    },
    close: async function close() {
      if (loadingModalId) {
        await Rocket.end(loadingModalId);
        loadingModalId = "";
      }
    },
  },

  warningCard: async function fire({ onConfirm, content }: IWarningCard) {
    var launchedId = await Rocket.launch(
      <WarningCard
        open={true}
        onClose={async () => await Rocket.end(launchedId)}
        onConfirm={onConfirm}
        content={content}
      />
    );
  },

  createOrEditThreeDModelModal: {
    open: async function open({
      createOrEditThreeDModel,
      title,
    }: ICreateOrEditThreeDModelModal) {
      var createDynamicQuery = {
        filter: {
          field: "productModel.modelId",
          operator: "isnull",
        },
      };

      var updateDynamicQuery = {
        filter: {
          field: "productModel.modelId",
          operator: "eq",
          value: createOrEditThreeDModel.id?.toString(),
          logic: "or",
          filters: [
            {
              field: "productModel.modelId",
              operator: "isnull",
            },
          ],
        },
      };

      var query = createOrEditThreeDModel.id
        ? updateDynamicQuery
        : createDynamicQuery;

      await checkStoreBeforeDispatching(
        "products",
        "products",
        ProductsGetListByDynamicAction({
          query,
          pagination: {
            pageIndex: 0,
            pageSize: 9999,
          },
        }),
        "withDynamic"
      );
      const products = store.getState().products.products.map((val: any) => {
        return {
          label: val.name,
          value: val.id,
          id: val.id,
        };
      });

      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <CreateOrEditThreeDModelModal
              createOrEditThreeDModel={createOrEditThreeDModel}
              products={products}
              title={title}
            />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },

  createOrEditProductModal: {
    open: async function open({
      title,
      product,
      productId,
    }: ICreateOrEditProductModal) {
      var createDynamicQuery = {
        filter: {
          field: "productModel.productId",
          operator: "isnull",
        },
      };

      var updateDynamicQuery = {
        filter: {
          field: "productModel.productId",
          operator: "eq",
          value: productId?.toString(),
          logic: "or",
          filters: [
            {
              field: "productModel.productId",
              operator: "isnull",
            },
          ],
        },
      };

      await checkStoreBeforeDispatching(
        "languages",
        "languages",
        LanguagesGetAllAction(new GetAllModel())
      );
      const languages = store
        .getState()
        .languages?.languages?.map((val: any) => {
          return {
            label: val.displayName,
            value: val.name,
            languageId: val.id,
          };
        });

      const query = productId ? updateDynamicQuery : createDynamicQuery;

      await checkStoreBeforeDispatching(
        "threeDModels",
        "threeDModels",
        ThreeDModelsGetListByDynamicAction({
          query,
          pagination: {
            pageIndex: 0,
            pageSize: 9999,
          },
        }),
        "withDynamic"
      );
      const threeDModels = store
        .getState()
        .threeDModels.threeDModels.map((val: any) => {
          return {
            label: val.name,
            value: val.id,
            id: val.id,
          };
        });

      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <CreateOrEditProductModal
              title={title}
              languages={languages}
              product={product}
              threeDModels={threeDModels}
            />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },

  createOrEditEditionModal: {
    open: async function open({
      createOrEditEdition,
    }: ICreateOrEditEditionModelModal) {
      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <CreateOrEditEditionModal edition={createOrEditEdition} />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },

  newCustomerRequestModal: {
    open: async function open({}) {
      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <NewCustomerRequestModal />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },

  createOrEditSiteLanguageModal: {
    open: async function open(languageModel?: CreateOrEditLanguageModel) {
      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <CreateOrEditSiteLanguageModal languageModel={languageModel} />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },

  previewThreeDModelModal: {
    open: async function open({ threeDModel }: IPreviewThreeDModel) {
      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <PreviewThreeDModel threeDModel={threeDModel} />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },

  createOrEditPaymentHistoryModal: {
    open: async function open({
      createOrEditPaymentHistory,
      editions,
      userProfileId,
    }: ICreateOrEditPaymentHistoryModal) {
      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <CreateOrEditPaymentHistoryModal
              paymentHistory={createOrEditPaymentHistory}
              editions={editions}
              userProfileId={userProfileId}
            />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },

  upgradeEditionModal: {
    open: async function open({ userProfileId }: { userProfileId: number }) {
      const editions = await store
        .dispatch(EditionsGetAllAction(new GetAllModel()))
        .unwrap()
        .then((res) => {
          if (res) {
            return res?.items.map((val: any) => {
              return {
                label: val.displayName,
                value: val.id,
                id: val.id,
              };
            });
          }
        });

      this.launchedId = await Rocket.launch(
        <Suspense fallback={<Loading />}>
          <div>
            <UpgrageEditionModal
              editions={editions}
              userProfileId={userProfileId}
            />
          </div>
        </Suspense>
      );
    },
    close: async function close() {
      if (this.launchedId) {
        await Rocket.end(this.launchedId);
        this.launchedId = null;
      }
    },
    launchedId: null as string | null,
  },
};

let modalCount = 0;
const rootMap = new Map();
export let activeModals: any = [];
export let loadingModalId = "";
let alertModalId = "alert-go";

export const Rocket = {
  launch: async function fire(props: any) {
    const modalId = `modal-root-${modalCount}`;
    modalCount++;

    const modalRoot = document.createElement("div");
    modalRoot.id = modalId;

    document.body.appendChild(modalRoot);

    const root = ReactDOM.createRoot(modalRoot);
    rootMap.set(modalId, root);

    // Store modal props with unique id
    activeModals.push({ id: modalId, props });

    root.render(createPortal(props, modalRoot));

    // Return the unique id
    return modalId;
  },
  end: async function fire(modalId?: string) {
    const modalIndex = activeModals.findIndex(
      (modal: any) => modal.id === modalId
    );

    if (modalIndex === -1) {
      throw new Error(`No modal found with id: ${modalId}`);
    }

    const modalRoot = document.getElementById(modalId!);

    if (!modalRoot) {
      throw new Error(`No modal found with id: ${modalId}`);
    }

    const root = rootMap.get(modalId);

    if (!root) {
      throw new Error("Root not initialized!");
    }

    root.unmount();
    rootMap.delete(modalId);

    activeModals = activeModals.filter((modal: any) => modal.id !== modalId);
    document.body.removeChild(modalRoot);
    modalCount--;
  },

  alertLaunch: async function fire(props: any) {
    const modalRoot = document.createElement("div");
    modalRoot.id = alertModalId;

    document.body.appendChild(modalRoot);

    const root = ReactDOM.createRoot(modalRoot);
    rootMap.set(alertModalId, root);

    root.render(createPortal(props, modalRoot));
  },

  alertEnd: async function fire() {
    const modalRoot = document.getElementById(alertModalId!);
    const root = rootMap.get(alertModalId);

    root.unmount();
    document.body.removeChild(modalRoot!);
  },
};
