import { Skeleton } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { FC } from "react";

interface TritophiaTypographyProps extends TypographyProps {
  isLoading?: boolean;
}

const TritophiaTypography: FC<TritophiaTypographyProps> = (props) => {
  return (
    <Typography style={{ width: "inherit" }} {...props}>
      {props.isLoading ? <Skeleton /> : props.children}
    </Typography>
  );
};

export default TritophiaTypography;
