import { setToken } from "../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../Middlewares/CallToBackend";

export const AuthAPI = {
  login: async (body: any) => {
    return await callToBackend(
      "api/Auth/Login",
      "POST",
      undefined,
      body,
      undefined,
      undefined
    )
      .then((response) => {
        if (response) {
          global.tokenCookieName = "Tritophia.AuthToken";

          setToken(
            response.data.data.accessToken.token,
            new Date(response.data.data.accessToken.expiration)
          );

          return response?.status;
        }

        return Promise.reject("E-mail ya da parola hatalı");
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
};
