import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";
import {
  IUserEditionInformationsChange,
  IUserEditionInformationsGetNewAmount,
} from "../../../Interfaces/UserEditionInformations/userEditionInformations.interface";

export const UserEditionInformationsGetAllAction = createAsyncThunk(
  "UserEditionInformationsGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/UserEditionInformations", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsGetFromAuthAction = createAsyncThunk(
  "UserEditionInformationsGetFromAuthAction",
  async (_, { rejectWithValue }) => {
    return await callToBackend(
      "api/UserEditionInformations/GetFromAuth",
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsGetByUserIdAction = createAsyncThunk(
  "UserEditionInformationsGetByUserIdAction",
  async (userId: number, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/UserEditionInformations/GetByUserId", { userId: userId }),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsGetAction = createAsyncThunk(
  "UserEditionInformationsGetAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/UserEditionInformations/" + id,
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsCreateAction = createAsyncThunk(
  "UserEditionInformationsCreateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/UserEditionInformations",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res?.data?.id;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsUpdateAction = createAsyncThunk(
  "UserEditionInformationsUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/UserEditionInformations",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsDeleteAction = createAsyncThunk(
  "UserEditionInformationsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/UserEditionInformations/" + id,
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return { success: res?.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsGetNewAmountAction = createAsyncThunk(
  "UserEditionInformationsGetNewAmountAction",
  async (data: IUserEditionInformationsGetNewAmount, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/UserEditionInformations/GetNewAmount", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const UserEditionInformationsChangeAction = createAsyncThunk(
  "UserEditionInformationsChangeAction",
  async (data: IUserEditionInformationsChange, { rejectWithValue }) => {
    return await callToBackend(
      "api/UserEditionInformations/Change",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
