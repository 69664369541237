import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardsGetCustomerDashboardInformationAction } from "../../../Actions/Customer/Dashboard/DashboardActions";
import { DashboardsState } from "./interface";

const initialState: DashboardsState = {
  dashboardData: {
    modelCount: 0,
    notConnectedModelCount: 0,
    notConnectedProductCount: 0,
    productCount: 0,
  },
  isDataLoaded: false,
};

const DashboardsSlice = createSlice({
  name: "DashboardsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DashboardsGetCustomerDashboardInformationAction.pending,
      (state, action) => {
        state.isDataLoaded = false;
      }
    );
    builder.addCase(
      DashboardsGetCustomerDashboardInformationAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.dashboardData = action.payload;
      }
    );
    builder.addCase(
      DashboardsGetCustomerDashboardInformationAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
      }
    );
  },
});

export default DashboardsSlice.reducer;
