import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductQrButtonsCreateAction,
  ProductQrButtonsDeleteAction,
  ProductQrButtonsGetAllAction,
  ProductQrButtonsUpdateAction,
} from "../../../Actions/Customer/ProductQrButtons/ProductQrButtonsActions";

const initialState: any = {
  getAllData: [],
  successMessage: "",
  errorMessage: "",
};

const ProductQrButtonsSlice = createSlice({
  name: "ProductQrButtonsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ProductQrButtonsGetAllAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductQrButtonsGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllData = action.payload.items;
      }
    );
    builder.addCase(
      ProductQrButtonsGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductQrButtonsCreateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductQrButtonsCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductQrButtonsCreateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductQrButtonsUpdateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductQrButtonsUpdateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllData = action.payload.items;
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductQrButtonsUpdateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductQrButtonsDeleteAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductQrButtonsDeleteAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductQrButtonsDeleteAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
  },
});

export default ProductQrButtonsSlice.reducer;
