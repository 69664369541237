import React, { FC, ChangeEvent, useState, useEffect } from "react";
import { Autocomplete, TextField, UseAutocompleteProps } from "@mui/material";
import { Color } from "../Color/Color";

interface SelectInputProps {
  id: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  options: any[];
  value?: any;
  onChange?: (event: ChangeEvent<{}>, value: any) => void;
  backgroundColor?: string;
  textColor?: string;
  padding?: string;
  multiple?: boolean;
  disableClearable?: boolean;
  renderOptionEnabled?: boolean;
  customValId?: boolean;
  myKey?: any;
}

const SelectInput: FC<SelectInputProps> = ({
  id,
  label,
  required = false,
  disabled = false,
  options,
  value,
  onChange,
  backgroundColor,
  textColor,
  padding = "9px",
  multiple = false,
  disableClearable = false,
  renderOptionEnabled = false,
  customValId = false,
  myKey,
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);

  const getValueById = (valueId: any) => {
    if (valueId) {
      if (customValId) {
        return options.find(
          (option) => option && option.languageId === valueId
        );
      } else {
        return options.find((option) => option && option.id === valueId);
      }
    }

    return null;
  };

  const getValueByMultipleId = (valueIds: number[]) => {
    const selectedOptions = options.filter((option) =>
      valueIds?.includes(option.id)
    );
    return selectedOptions.length > 0 ? selectedOptions : [];
  };

  useEffect(() => {
    if (multiple) {
      var newSelectedValues = getValueByMultipleId(value);
      setSelectedValues(newSelectedValues);
    } else {
      var newSelectedValue = getValueById(value);
      setSelectedValue(newSelectedValue);
    }
  }, [value, multiple]);

  const handleChange = (event: ChangeEvent<{}>, option: any) => {
    !multiple && setSelectedValue(option);
    multiple && setSelectedValues(option);
    if (onChange) {
      return onChange(event, option);
    }
  };

  if (multiple) {
    return (
      <Autocomplete
        disablePortal
        id={id}
        options={options}
        getOptionLabel={(option) => option?.label}
        sx={{ width: "100%" }}
        value={selectedValues}
        onChange={handleChange}
        clearOnBlur={true}
        multiple
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            label={label === undefined ? "" : label.toString()}
            required={required}
            disabled={disabled}
            inputProps={{
              ...params.inputProps,
              style: { color: textColor },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "18px",
                backgroundColor: backgroundColor
                  ? backgroundColor
                  : Color.White,
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: "700",
                fontFamily: "Nunito Sans",
                "&.Mui-focused fieldset": {
                  borderColor: Color.Blue3,
                },
                padding: { padding },
                paddingLeft: "8px",
                paddingRight: "8px",
              },
              "& label.Mui-focused": {
                color: Color.Blue3,
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        disablePortal
        id={id}
        options={options}
        renderOption={
          renderOptionEnabled
            ? (props, option, state) => (
                <li {...props} key={option.inputId}>
                  {option.label}
                </li>
              )
            : undefined
        }
        key={myKey}
        getOptionLabel={(option: any) => option.label.toString()}
        sx={{ width: "100%" }}
        value={selectedValue}
        onChange={handleChange}
        clearOnBlur={true}
        disableClearable={disableClearable}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            label={label === undefined ? "" : label.toString()}
            required={required}
            disabled={disabled}
            inputProps={{
              ...params.inputProps,
              style: { color: textColor },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "18px",
                backgroundColor: backgroundColor
                  ? backgroundColor
                  : Color.White,
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: "700",
                fontFamily: "Nunito Sans",
                "&.Mui-focused fieldset": {
                  borderColor: Color.Blue3,
                },
                padding: { padding },
                paddingLeft: "8px",
                paddingRight: "8px",
              },
              "& label.Mui-focused": {
                color: Color.Blue3,
              },
              "& .MuiFormLabel-asterisk": {
                color: "red",
              },
            }}
          />
        )}
      />
    );
  }
};

export default SelectInput;
