import { IIntegrations } from "../../Interfaces/Integrations/integrations.interface";

export class Integrations implements IIntegrations {
  id: number | undefined;
  webSiteUrl: string;
  productCardContainerId: string;
  dataKey: string;
  userProfileId: number | undefined;

  constructor(
    id: number | undefined = undefined,
    webSiteUrl: string = "",
    productCardContainerId: string = "",
    dataKey: string = "",
    userProfileId: number | undefined = undefined
  ) {
    this.id = id;
    this.webSiteUrl = webSiteUrl;
    this.productCardContainerId = productCardContainerId;
    this.dataKey = dataKey;
    this.userProfileId = userProfileId;
  }
}
