import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EditionsCreateAction,
  EditionsDeleteAction,
  EditionsGetAction,
  EditionsGetAllAction,
  EditionsUpdateAction,
} from "../../../Actions/Admin/Editions/EditionsActions";
import { EditionModel } from "../../../Models/Core/Admin/Editions/EditionModel";

const initialState: any = {
  getAllData: [],
  edition: new EditionModel(),
  count: 0,
  successMessage: "",
  errorMessage: "",
};

const EditionsSlice = createSlice({
  name: "EditionsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(EditionsGetAllAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      EditionsGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.count = action.payload.count;
        state.getAllData = action.payload.items;
      }
    );
    builder.addCase(
      EditionsGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(EditionsGetAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      EditionsGetAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getData = action.payload;
      }
    );
    builder.addCase(
      EditionsGetAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(EditionsCreateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      EditionsCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        // state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      EditionsCreateAction.rejected,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(EditionsUpdateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      EditionsUpdateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      EditionsUpdateAction.rejected,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(EditionsDeleteAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      EditionsDeleteAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      EditionsDeleteAction.rejected,
      (state, action: PayloadAction<any>) => {}
    );
  },
});

export default EditionsSlice.reducer;
