import { createAsyncThunk } from "@reduxjs/toolkit";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";

export const ProductQrButtonsGetAllAction = createAsyncThunk(
  "ProductQrButtonsGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/ProductQrButtons", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductQrButtonsCreateAction = createAsyncThunk(
  "ProductQrButtonsCreateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/ProductQrButtons",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return { success: res.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductQrButtonsUpdateAction = createAsyncThunk(
  "ProductQrButtonsUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/ProductQrButtons",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductQrButtonsDeleteAction = createAsyncThunk(
  "ProductQrButtonsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/ProductQrButtons", id),
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
