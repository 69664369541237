import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaymentsCreateAction,
  PaymentsDeleteAction,
  PaymentsGetAction,
  PaymentsGetAllAction,
  PaymentsGetFromAuthAction,
  PaymentsGetListByUserIdAction,
  PaymentsUpdateAction,
} from "../../../Actions/Admin/Payments/PaymentsActions";
import { IPaymentsState } from "./interface";
import { Paginations } from "../../../DTOs/Paginations/paginations.dto";

const initialState: IPaymentsState = {
  payments: [],
  count: 0,
  isLoading: false,
  successMessage: "",
  errorMessage: "",
  isDataLoaded: false,
  pagination: new Paginations(),
};

const PaymentsSlice = createSlice({
  name: "PaymentsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PaymentsGetAllAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(
      PaymentsGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.payments = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      PaymentsGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.isLoading = false;
      }
    );
    builder.addCase(PaymentsGetFromAuthAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(
      PaymentsGetFromAuthAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pagination = new Paginations(
          action.payload.index,
          action.payload.size
        );
        state.isDataLoaded = true;
        state.count = action.payload.count;
        state.payments = action.payload.items;
        state.isLoading = false;
      }
    );
    builder.addCase(
      PaymentsGetFromAuthAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.isLoading = false;
      }
    );
    builder.addCase(PaymentsGetListByUserIdAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(
      PaymentsGetListByUserIdAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.count = action.payload.count;
        state.payments = action.payload.items;
        state.isLoading = false;
      }
    );
    builder.addCase(
      PaymentsGetListByUserIdAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.isLoading = false;
      }
    );
    builder.addCase(PaymentsGetAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(
      PaymentsGetAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.isLoading = false;
      }
    );
    builder.addCase(
      PaymentsGetAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
        state.isLoading = false;
      }
    );
    builder.addCase(PaymentsCreateAction.pending, (state, action) => {
      state.payments = [];
      state.successMessage = "";
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(
      PaymentsCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      PaymentsCreateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      }
    );
    builder.addCase(PaymentsUpdateAction.pending, (state, action) => {
      state.payments = [];
      state.successMessage = "";
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(
      PaymentsUpdateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
        state.isLoading = false;
      }
    );
    builder.addCase(
      PaymentsUpdateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      }
    );
    builder.addCase(PaymentsDeleteAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(
      PaymentsDeleteAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
        state.isLoading = false;
      }
    );
    builder.addCase(
      PaymentsDeleteAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      }
    );
  },
});

export default PaymentsSlice.reducer;
