import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CustomersGetAction,
  CustomersGetAllAction,
  CustomersGetFromAuthAction,
} from "../../../Actions/Shared/Customers/CustomersActions";
import { UsersModel } from "../../../Models/Core/Users/UsersModel";

const initialState: any = {
  getAllData: [],
  user: new UsersModel(),
  count: 0,
  successMessage: "",
  errorMessage: "",
};

const CustomersSlice = createSlice({
  name: "CustomersSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(CustomersGetAllAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      CustomersGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllData = action.payload.items;
        state.count = action.payload.count;
      }
    );
    builder.addCase(
      CustomersGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(CustomersGetFromAuthAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      CustomersGetFromAuthAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.user = action.payload;
      }
    );
    builder.addCase(
      CustomersGetFromAuthAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(CustomersGetAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      CustomersGetAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.user = action.payload;
      }
    );
    builder.addCase(
      CustomersGetAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
  },
});

export default CustomersSlice.reducer;
