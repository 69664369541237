import { EditionPriceModel } from "../EditionPrices/EditionPriceModel";

export class EditionModel {
  id: number | undefined;
  description: string | undefined;
  editionPrices: EditionPriceModel[] = [];
  displayName: string | undefined;
  name: string | undefined;
  productCount: number | undefined;
  storage: number | undefined;
}
