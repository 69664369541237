import {
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import tritophiaIcon from "../../../Assets/Images/tritophia-icon.png";
import Button from "../../../Components/Shared/Button/Button";
import HStack from "../../../Components/Shared/HStack/HStack";
import { TextInput } from "../../../Components/Shared/Input";
import VStack from "../../../Components/Shared/VStack/VStack";
import { Roc } from "../../../Config/Rocket";
import { isAdmin } from "../../../Helpers/AuthenticationHelper";
import { Card } from "../../../Layouts/Card/Card";
import { AuthAPI } from "../../../Services/APIs/AuthAPI";
import { UserForLoginModel } from "../../../Services/Models/Core/UserForLoginModel";
import { useTranslation } from "react-i18next";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userForLoginModel, setUserForLoginModel] = useState<UserForLoginModel>(
    new UserForLoginModel()
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Roc.loading.open();

    if (userForLoginModel.email && userForLoginModel.password) {
      await AuthAPI.login(userForLoginModel)
        .then((res) => {
          if (res === 200) {
            navigate(isAdmin() ? "/admin/main" : "/customer/main");
          }
        })
        .catch((err) => {
          setUserForLoginModel(new UserForLoginModel());
          debugger;
          Roc.alert({ type: "error", content: err.data.MessageDetail });
        })
        .finally(() => Roc.loading.close());
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <FormControl sx={{ width: "100%" }}>
        <Grid container style={{ marginTop: "80px" }}>
          <Grid item xs={0} sm={1} md={2} lg={3} xl={4.5}></Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={6}
            xl={3}
            sx={{ pl: { xs: 2, md: 4 }, pr: { xs: 2, md: 4 } }}
            style={{ height: 250 }}
          >
            <Card>
              <HStack justifyContent="center" alignItems="center">
                <img src={tritophiaIcon} width={96} alt="logo" />
              </HStack>
              <HStack
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 16, marginBottom: 16 }}
              >
                <Typography
                  variant="h4"
                  align="center"
                  style={{ width: "100%" }}
                >
                  {t("login.welcome")}
                </Typography>
              </HStack>
              <Divider />
              <VStack spacing="32px" style={{ marginTop: 16 }}>
                <VStack spacing="24px">
                  <TextInput
                    id="email"
                    label={t("login.email")}
                    placeholder={t("login.emailAddress")}
                    type="email"
                    autoComplete="email"
                    value={userForLoginModel.email}
                    onChange={(value) =>
                      setUserForLoginModel((prevUserForLoginModel) => ({
                        ...prevUserForLoginModel,
                        email: value,
                      }))
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <svg
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 6C5 4.61929 6.11929 3.5 7.5 3.5C8.88071 3.5 10 4.61929 10 6C10 7.38071 8.88071 8.5 7.5 8.5C6.11929 8.5 5 7.38071 5 6Z"
                            fill="#1D1929"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.5 0.5C3.35786 0.5 0 3.85786 0 8C0 12.1421 3.35786 15.5 7.5 15.5C11.6421 15.5 15 12.1421 15 8C15 3.85786 11.6421 0.5 7.5 0.5ZM1 8C1 4.41015 3.91015 1.5 7.5 1.5C11.0899 1.5 14 4.41015 14 8C14 9.84956 13.2275 11.5187 11.9875 12.7024C11.8365 10.9086 10.3328 9.5 8.5 9.5H6.5C4.66724 9.5 3.16345 10.9086 3.01247 12.7024C1.77251 11.5187 1 9.84956 1 8Z"
                            fill="#1D1929"
                          />
                        </svg>
                      </InputAdornment>
                    }
                    required
                  />
                  <TextInput
                    id="password"
                    label={t("login.password")}
                    type="password"
                    placeholder={t("login.password")}
                    value={userForLoginModel.password}
                    onChange={(value) =>
                      setUserForLoginModel((prevUserForLoginModel) => ({
                        ...prevUserForLoginModel,
                        password: value,
                      }))
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 4V6.5H11.5C12.3284 6.5 13 7.17157 13 8V14C13 14.8284 12.3284 15.5 11.5 15.5H1.5C0.671573 15.5 0 14.8284 0 14V8C0 7.17157 0.671573 6.5 1.5 6.5H3V4C3 2.067 4.567 0.5 6.5 0.5C8.433 0.5 10 2.067 10 4ZM4 4C4 2.61929 5.11929 1.5 6.5 1.5C7.88071 1.5 9 2.61929 9 4V6.5H4V4Z"
                            fill="#1D1929"
                          />
                        </svg>
                      </InputAdornment>
                    }
                    required
                  />
                  <Button
                    label={t("login.login")}
                    backgroundColor="#354998"
                    variant="contained"
                    width="100%"
                  />
                </VStack>
              </VStack>
            </Card>
          </Grid>
          <Grid item xs={0} sm={1} md={2} lg={3} xl={4.5}></Grid>
        </Grid>
      </FormControl>
    </form>
  );
};

export default Login;
