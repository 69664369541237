import { Grid, IconButton } from "@mui/material";
import { FC } from "react";
import { SvgIcons } from "../../../../../Assets/SvgIcons/SvgIcons";
import "../../../../../Assets/css/DragAndDrop.css";
import { Roc } from "../../../../../Config/Rocket";
import Modal from "../../../../../Layouts/Modal/Modal";
import HStack from "../../../../Shared/HStack/HStack";
import VStack from "../../../../Shared/VStack/VStack";
import "./style.css";
import { remoteServiceBaseUrl } from "../../../../../Config/AppBaseConsts";

interface IPreviewThreeDModelProps {
  threeDModel: string;
}

const PreviewThreeDModel: FC<IPreviewThreeDModelProps> = ({ threeDModel }) => {
  return (
    <Modal
      open={true}
      customGridVal={{ xs: 12, sm: 10, md: 10, lg: 6, xl: 6 }}
      children={
        <VStack>
          <HStack justifyContent="flex-start" width="13px">
            <IconButton onClick={() => Roc.previewThreeDModelModal.close()}>
              {SvgIcons.cancelIcon()}
            </IconButton>
          </HStack>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12}>
              <model-viewer
                alt="Chair"
                src={threeDModel}
                ar
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
              ></model-viewer>
            </Grid>
          </Grid>
        </VStack>
      }
    ></Modal>
  );
};

export default PreviewThreeDModel;
