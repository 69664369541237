import { Grid, GridSize, Modal as MuiModal } from "@mui/material";
import { FC, useRef } from "react";
import { Card } from "../Card/Card";

interface IGridSizes {
  xs?: boolean | GridSize;
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
  xl?: boolean | GridSize;
}

interface IPropState {
  children?: React.ReactNode;
  open: boolean;
  customGridVal?: IGridSizes;
  fullSizeOfPageHeight?: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  overflow?: boolean;
}

const Modal: FC<IPropState> = ({
  children,
  open,
  customGridVal = { xs: 12, sm: 10, md: 8, lg: 6, xl: 4 },
  fullSizeOfPageHeight = false,
  onClose,
  overflow = false,
}) => {
  const calculateOtherGridSizes = (gridSize: any) => {
    return gridSize !== 12 ? (12 - gridSize) / 2 : 0;
  };

  const modalRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (event: any) => {
    if (event.code === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <MuiModal
      open={open}
      sx={{ zIndex: 120, overflowY: overflow ? "scroll" : null }}
      onClose={(event, reason) => {
        reason && onClose && onClose(false);
      }}
      onKeyDown={handleKeyDown}
    >
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid
          item
          xs={calculateOtherGridSizes(customGridVal?.xs)}
          sm={calculateOtherGridSizes(customGridVal?.sm)}
          md={calculateOtherGridSizes(customGridVal?.md)}
          lg={calculateOtherGridSizes(customGridVal?.lg)}
          xl={calculateOtherGridSizes(customGridVal?.xl)}
        ></Grid>
        <Grid
          item
          xs={customGridVal?.xs}
          sm={customGridVal?.sm}
          md={customGridVal?.md}
          lg={customGridVal?.lg}
          xl={customGridVal?.xl}
          sx={{
            pl: { xs: 2, md: 3, xl: 0 },
            pr: { xs: 2, md: 3, xl: 0 },
            mt: { xs: 2, md: 0 },
            mb: { xs: 2, md: 0 },
          }}
          ref={modalRef}
        >
          <Card fullSizeOfPageHeight={fullSizeOfPageHeight}>{children}</Card>
        </Grid>
        <Grid
          item
          xs={calculateOtherGridSizes(customGridVal?.xs)}
          sm={calculateOtherGridSizes(customGridVal?.sm)}
          md={calculateOtherGridSizes(customGridVal?.md)}
          lg={calculateOtherGridSizes(customGridVal?.lg)}
          xl={calculateOtherGridSizes(customGridVal?.xl)}
        ></Grid>
      </Grid>
    </MuiModal>
  );
};

export default Modal;
