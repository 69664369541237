import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";
import { CreateOrEditIntegrationModel } from "../../../Models/Core/Admin/Integrations/CreateOrEditIntegrationModel";

export const IntegrationsGetAllAction = createAsyncThunk(
  "IntegrationsGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Integrations", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const IntegrationsGetAction = createAsyncThunk(
  "IntegrationsGetAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/Integrations/" + id,
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const IntegrationsGetFromAuthAction = createAsyncThunk(
  "IntegrationsGetFromAuthAction",
  async (_, { rejectWithValue }) => {
    return await callToBackend(
      "api/Integrations/GetFromAuth",
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const IntegrationsGetByUserIdAction = createAsyncThunk(
  "IntegrationsGetByUserIdAction",
  async (userId: number, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Integrations/GetByUserId", { userId: userId }),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const IntegrationsCreateAction = createAsyncThunk(
  "IntegrationsCreateAction",
  async (data: CreateOrEditIntegrationModel, { rejectWithValue }) => {
    return await callToBackend(
      "api/Integrations",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return { success: res.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const IntegrationsUpdateAction = createAsyncThunk(
  "IntegrationsUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Integrations",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const IntegrationsDeleteAction = createAsyncThunk(
  "IntegrationsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Integrations", id),
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
