import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setToken = (authToken?: string, expireDate?: Date) => {
  cookies.set(global.tokenCookieName, authToken, {
    path: "/",
    expires: expireDate,
  });
};

export const setRefreshToken = (authToken?: string, expireDate?: Date) => {
  cookies.set(global.refreshTokenCookieName, authToken, {
    path: "/",
    expires: expireDate,
  });
};

export const getToken = () => {
  return cookies.get("Tritophia.AuthToken");
};

export const getRefreshToken = (): string => {
  return cookies.get(global.refreshTokenCookieName);
};

export const removeToken = () => {
  cookies.remove("Tritophia.AuthToken", { path: "/" });
};

export const getCookieValue = (key: string): string | null => {
  var equalities = document.cookie.split("; ");
  for (var i = 0; i < equalities.length; i++) {
    if (!equalities[i]) {
      continue;
    }

    var splitted = equalities[i].split("=");
    if (splitted.length !== 2) {
      continue;
    }

    if (decodeURIComponent(splitted[0]) === key) {
      return decodeURIComponent(splitted[1] || "");
    }
  }

  return null;
};

export const clearToken = () => {
  document.cookie =
    "Tritophia.AuthToken=;" + new Date().toUTCString() + "  ;path=/;";
  // document.cookie =
  //   "Tritophia.AuthRefreshToken=;" + new Date().toUTCString() + "  ;path=/;";
  // window.location.reload();
};

export const isAdmin = () => {
  if (getToken()) {
    const decodedToken: any = jwtDecode(getToken());

    return decodedToken[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ] === "Admin"
      ? true
      : false;
  } else {
    return false;
  }
};
