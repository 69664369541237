import { Suspense } from "react";

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense
      fallback={
        <></>
        // <Lottie
        //   className={styles.loadable}
        //   animationData={Spinner}
        //   loop
        //   play
        //   speed={2}
        //   style={{ width: 80, height: 80 }}
        // />
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
