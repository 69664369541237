import { createAsyncThunk } from "@reduxjs/toolkit";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { getToken } from "../../../../Helpers/AuthenticationHelper";

export const DashboardsGetAdminDashboardInformationAction = createAsyncThunk(
  "DashboardsGetAdminDashboardInformationAction",
  async (_, { rejectWithValue }) => {
    return await callToBackend(
      "api/Dashboards/GetAdminDashboardInformation",
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
