import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";
import { CreateOrEditProduct } from "../../../DTOs/Products/products.dto";
import { Roc } from "../../../../Config/Rocket";

export const ProductsGetAllAction = createAsyncThunk(
  "ProductsGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Products", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductsGetListByDynamicAction = createAsyncThunk(
  "ProductsGetListByDynamicAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Products/GetList/ByDynamic", data.pagination),
      "POST",
      undefined,
      data.query,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const ProductsGetAction = createAsyncThunk<CreateOrEditProduct, number>(
  "ProductsGetAction",
  async (id, { rejectWithValue }) => {
    return await callToBackend(
      "api/Products/" + id,
      "GET",
      CreateOrEditProduct,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductsCreateAction = createAsyncThunk(
  "ProductsCreateAction",
  async (data: CreateOrEditProduct) => {
    return await callToBackend(
      "api/Products",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        Roc.alert({ type: "success", content: "Başarıyla Oluşturuldu" });
        return res.data.data;
      })
      .catch((error) => {
        Roc.alert({ type: "error", content: error?.data?.MessageDetail });
      });
  }
);

export const ProductsUpdateAction = createAsyncThunk(
  "ProductsUpdateAction",
  async (data: CreateOrEditProduct, { rejectWithValue }) => {
    return await callToBackend(
      "api/Products",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return { success: res.statusText, updatedProduct: res.data.data };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const ProductsDeleteAction = createAsyncThunk(
  "ProductsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Products/" + id,
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data.result;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
