import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductArButtonsCreateAction,
  ProductArButtonsDeleteAction,
  ProductArButtonsGetAllAction,
  ProductArButtonsUpdateAction,
} from "../../../Actions/Customer/ProductArButtons/ProductArButtonsActions";

const initialState: any = {
  getAllData: [],
  successMessage: "",
  errorMessage: "",
};

const ProductArButtonsSlice = createSlice({
  name: "ProductArButtonsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ProductArButtonsGetAllAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductArButtonsGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllData = action.payload.items;
      }
    );
    builder.addCase(
      ProductArButtonsGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductArButtonsCreateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductArButtonsCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductArButtonsCreateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductArButtonsUpdateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductArButtonsUpdateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllData = action.payload.items;
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductArButtonsUpdateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductArButtonsDeleteAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductArButtonsDeleteAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductArButtonsDeleteAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
  },
});

export default ProductArButtonsSlice.reducer;
