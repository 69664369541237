import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductsCreateAction,
  ProductsGetAllAction,
  ProductsGetListByDynamicAction,
} from "../../../Actions/Customer/Products/ProductsActions";
import { IProductsState } from "./interface";
import { Paginations } from "../../../DTOs/Paginations/paginations.dto";

const initialState: IProductsState = {
  products: [],
  count: 0,
  successMessage: "",
  errorMessage: "",
  isDataLoaded: false,
  pagination: new Paginations(),
};

const ProductsSlice = createSlice({
  name: "ProductSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ProductsGetAllAction.pending, (state, action) => {
      state.products = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductsGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pagination = new Paginations(
          action.payload.index,
          action.payload.size
        );
        state.isDataLoaded = true;
        state.dynamicAction = "withoutDynamic";
        state.products = action.payload.items;
        state.count = action.payload.count;
      }
    );
    builder.addCase(
      ProductsGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductsGetListByDynamicAction.pending, (state, action) => {
      state.products = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductsGetListByDynamicAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pagination = new Paginations(
          action.payload.index,
          action.payload.size
        );
        state.isDataLoaded = true;
        state.dynamicAction = "withDynamic";
        state.products = action.payload.items;
        state.count = action.payload.count;
      }
    );
    builder.addCase(
      ProductsGetListByDynamicAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductsCreateAction.pending, (state, action) => {
      state.products = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductsCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        // state.successMessage = action.payload;
      }
    );
    builder.addCase(
      ProductsCreateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
  },
});

export default ProductsSlice.reducer;
