import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductUrlNamesCreateAction,
  ProductUrlNamesDeleteAction,
  ProductUrlNamesGetAllAction,
  ProductUrlNamesUpdateAction,
} from "../../../Actions/Customer/ProductUrlNames/ProductUrlNamesActions";

const initialState: any = {
  getAllData: [],
  successMessage: "",
  errorMessage: "",
};

const ProductUrlNamesSlice = createSlice({
  name: "ProductUrlNamesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ProductUrlNamesGetAllAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductUrlNamesGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllData = action.payload.items;
      }
    );
    builder.addCase(
      ProductUrlNamesGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductUrlNamesCreateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductUrlNamesCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductUrlNamesCreateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductUrlNamesUpdateAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductUrlNamesUpdateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllData = action.payload.items;
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductUrlNamesUpdateAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(ProductUrlNamesDeleteAction.pending, (state, action) => {
      state.getAllData = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      ProductUrlNamesDeleteAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      ProductUrlNamesDeleteAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
  },
});

export default ProductUrlNamesSlice.reducer;
