import { Box } from "@mui/material";
import { FC } from "react";

interface ICardProps {
  children?: React.ReactNode;
  backgroundColor?: string;
  backgroundImage?: string;
  width?: string;
  boxShadow?: boolean;
  fullSizeOfPageHeight?: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  gradientBackground?: boolean;
  onClick?: any;
  hoverEffect?: boolean;
}

export const Card: FC<ICardProps> = ({
  backgroundColor = "white",
  backgroundImage,
  width,
  children,
  boxShadow = true,
  fullSizeOfPageHeight = false,
  onClose,
  gradientBackground = false,
  onClick,
  hoverEffect = false,
}) => {
  const handleClickAway = () => {
    onClose && onClose(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor ? backgroundColor : "white",
        backgroundImage: backgroundImage,
        filter: boxShadow
          ? "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.08))"
          : "none",
        borderRadius: "16px",
        padding: { xl: "32px", lg: "32px", md: "24px", sm: "16px", xs: "16px" },
        width: width ? width : "-webkit-fill-available",
        height: fullSizeOfPageHeight ? "85%" : "auto",
        background: gradientBackground
          ? "rgb(9,9,121)" +
            "linear-gradient(90deg, rgba(9,9,121,0.5648853291316527) 0%, rgba(140,82,236,1) 100%);"
          : undefined,
        cursor: hoverEffect ? "pointer" : "default",
        "&:hover": {
          backgroundColor: hoverEffect ? "red" : "none",
        },
      }}
      onClick={onClick ? onClick : handleClickAway}
    >
      {children}
    </Box>
  );
};
