import store, { RootState } from "../Services/store";
import { Roc } from "../Config/Rocket";
import { Paginations } from "../Services/DTOs/Paginations/paginations.dto";

export const checkStoreBeforeDispatching = async (
  stateKey: keyof RootState,
  subKey: keyof RootState[keyof RootState],
  dispatchAction: any,
  dynamicAction?: "withoutDynamic" | "withDynamic",
  withLoading: boolean = true,
  pagination?: Paginations
) => {
  const currentState = store.getState();

  if (
    (stateKey in currentState && !currentState[stateKey].isDataLoaded) ||
    (dynamicAction && currentState[stateKey].dynamicAction !== dynamicAction) ||
    (pagination &&
      JSON.stringify(currentState[stateKey].pagination) !==
        JSON.stringify(pagination))
  ) {
    if (withLoading) {
      Roc.loading.open();
    }

    await store.dispatch(dispatchAction);

    if (withLoading) {
      Roc.loading.close();
    }
  } else {
    return currentState[stateKey][subKey];
  }
};
