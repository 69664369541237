import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";
import { CreateOrEditEditionModel } from "../../../Models/Core/Admin/Editions/CreateOrEditEditionModel";

export const EditionsGetAllAction = createAsyncThunk(
  "EditionsGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Editions", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const EditionsGetAction = createAsyncThunk(
  "EditionsGetAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/Editions/" + id,
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const EditionsGetFromAuthAction = createAsyncThunk(
  "EditionsGetFromAuthAction",
  async (_, { rejectWithValue }) => {
    return await callToBackend(
      "api/Editions/GetFromAuth",
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const EditionsCreateAction = createAsyncThunk(
  "EditionsCreateAction",
  async (data: CreateOrEditEditionModel, { rejectWithValue }) => {
    return await callToBackend(
      "api/Editions",
      "POST",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res?.data?.data.id;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const EditionsUpdateAction = createAsyncThunk(
  "EditionsUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Editions",
      "PUT",
      undefined,
      data,
      getToken()
    )
      .then((res) => {
        return res?.data?.data.id;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const EditionsDeleteAction = createAsyncThunk(
  "EditionsDeleteAction",
  async (id: any, { rejectWithValue }) => {
    return await callToBackend(
      "api/Editions/" + id,
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return { success: res?.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
