import { FC, MouseEvent } from "react";
import { Button as MUIButton } from "@mui/material";
import { Color } from "../Color/Color";

export interface ButtonProps {
  label?: string;
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  hoverColor?: string;
  disabled?: boolean;
  width?: string;
  type?: any;
  textDecoration?: boolean;
}

const Button: FC<ButtonProps> = ({
  label,
  variant = "outlined",
  size = "large",
  onClick,
  startIcon,
  endIcon,
  textColor,
  borderColor,
  backgroundColor,
  hoverColor = Color.TritophiaHoverPurple,
  disabled = false,
  width,
  type = "submit",
  textDecoration,
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  let buttonStyle = {};

  if (variant === "contained") {
    buttonStyle = {
      backgroundColor: backgroundColor
        ? backgroundColor
        : Color.TritophiaPurple,
      color: textColor ? textColor : Color.White,
      "&:hover": {
        backgroundColor: hoverColor,
      },
    };
  } else if (variant === "text") {
    buttonStyle = {
      color: textColor ? textColor : Color.TritophiaPurple,
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: textDecoration && "underline",
      },
    };
  } else {
    buttonStyle = {
      borderColor: borderColor ? borderColor : Color.Gray11,
      color: textColor ? textColor : Color.Gray2,
      "&:hover": {
        borderColor: hoverColor,
      },
    };
  }

  return (
    <MUIButton
      id={label}
      variant={variant}
      size={size}
      onClick={handleClick}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      disabled={disabled}
      sx={{
        borderRadius: "12px",
        textTransform: "none",
        width: width !== undefined ? width : null,
        ...buttonStyle,
        alignSelf: "end",
      }}
    >
      {label === undefined ? "" : label}
    </MUIButton>
  );
};

export default Button;
