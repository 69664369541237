import axios from "axios";
import { remoteServiceBaseUrl } from "../Config/AppBaseConsts";
import { getCurrentLanguage } from "../Helpers/LanguageHelper";

const api = axios.create({
  baseURL: remoteServiceBaseUrl,
});

api.interceptors.request.use(function (config) {
  return config;
});

api.interceptors.response.use(
  (response) => {
    if (response?.data?.IsSuccess === false) {
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    // const a = error.response;
    // var errRes = ErrorHandler(error);
    // if (errRes?.type === "sessionExpired") {
    //   Swal.fire({
    //     title: L("Error"),
    //     text: errRes.message,
    //     icon: "error",
    //     showCancelButton: false,
    //     confirmButtonText: L("Ok"),
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       window.location.reload();
    //     }
    //   });
    // }
    // return Promise.reject(error);
  }
);

export const CallToBackend = async (
  ENDPOINT: string,
  METHOD: string,
  MODEL: any,
  BODY?: object,
  HEADERSACCESSTOKEN?: any,
  formData?: boolean
): Promise<typeof MODEL> => {
  return await api.request({
    method: `${METHOD}`,
    url: `${ENDPOINT}`,
    headers: {
      "Accept-Language": getCurrentLanguage(),
      "Content-Type": formData ? "multipart/form-data" : "application/json",
      accept: "*",
      Authorization: `Bearer ${HEADERSACCESSTOKEN}`,
    },
    data: BODY,
  });
};
