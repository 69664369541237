import { FC } from "react";
import { Navigate } from "react-router-dom";
import { getToken, isAdmin } from "../Helpers/AuthenticationHelper";

interface IGuestRoute {
  children?: any;
}

const GuestRoute: FC<IGuestRoute> = ({ children }) => {
  if (!getToken()) {
    return children;
  } else {
    return <Navigate to={isAdmin() ? "/admin/main" : "/customer/main"} />;
  }
};

export default GuestRoute;
