export class SideAppMenuItem {
  name = "";
  icon: JSX.Element = (<svg></svg>);
  route = "";
  items: SideAppMenuItem[];
  active?: boolean = false;

  constructor(
    name: string,
    icon: JSX.Element,
    route: string,
    items?: SideAppMenuItem[]
  ) {
    this.name = name;
    this.icon = icon;
    this.route = route;

    if (items === undefined) {
      this.items = [];
    } else {
      this.items = items;
    }
  }
}
