import { Box, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import tritophiaWithoutText from "../../Assets/Images/tritophia-purple-without-text.png";
import tritophiaPurple from "../../Assets/Images/tritophia-purple.png";
import Center from "../../Components/Shared/Center/Center";
import { Color } from "../../Components/Shared/Color/Color";
import HStack from "../../Components/Shared/HStack/HStack";
import VStack from "../../Components/Shared/VStack/VStack";
import {
  isAdmin,
  removeToken,
  setToken,
} from "../../Helpers/AuthenticationHelper";
import { UserType } from "../../Services/Models/Enums/UserType";
import { RootState } from "../../Services/store";
import styles from "./Sidebar.module.css";
import { AppMenuItem } from "./app-menu-item";
import { AppNavigationService } from "./appNavigationService";
import "./Sidebar.css";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";

export const UPDATE = "UPDATE";

const closeSideBarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#8A24EA"
    stroke-width="2.4"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <polyline points="15 18 9 12 15 6" />{" "}
    </g>
  </svg>
);

interface ISidebar {
  sidebarIsOpen: boolean;
}

const Sidebar: FC<ISidebar> = ({ sidebarIsOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const menuService = new AppNavigationService();
  const { t, i18n } = useTranslation();
  const cookies = new Cookies();

  const [menu, setMenu] = useState(menuService.getMenu());

  const userType = isAdmin() ? UserType.Admin : UserType.Customer;
  const setSidebar = () => {
    dispatch({
      type: UPDATE,
      payload: !sidebarIsOpen,
    });
  };

  const isButtonActive = (item: AppMenuItem): boolean => {
    let urlString = location.pathname;

    let exactMatch = urlString === item.route.split("?")[0];

    return exactMatch;
  };

  const changeIconActive = (item: AppMenuItem): JSX.Element => {
    let isActiveMenu = isButtonActive(item);
    if (!isActiveMenu) {
      return item.icon;
    }

    let result: JSX.Element | void;

    for (let index = 0; index < menu.items.length; index++) {
      const menuItem = menu.items[index];

      if (menuItem === item) {
        result = menuService.getMenuIcon(index, "#3363FF");
        break;
      }
    }

    if (result! === undefined) {
      return item.icon;
    }

    return result;
  };

  const isOpenMenuItem = (item: AppMenuItem) => {
    var test = menu;
    let currentChangeMenu = test.items.find((x: any) => x.name === item.name);
    currentChangeMenu!.active = !currentChangeMenu!.active;

    setMenu({ ...menu });
  };

  const logoutClick = () => {
    removeToken();
    dispatch({ type: "LOGOUT" });
    navigate("/account/login");
  };

  const handleRouteClick = (route: string) => {
    navigate(route);
  };

  const changeLanguage = (lng: string) => {
    cookies.set("language", lng);
    i18n.changeLanguage(lng);
  };

  return (
    <VStack
      spacing="24px"
      bg={Color.White}
      height="100%"
      sx={{
        width: {
          xs: sidebarIsOpen ? "240px" : "0px",
          lg: sidebarIsOpen ? "calc(100% - 81%)" : "48px",
          xl: sidebarIsOpen ? "calc(100% - 84%)" : "48px",
        },
        transition: "width 200ms linear",
        overflow: { xs: "visible", lg: "none" },
        margin: { xs: 0, lg: "auto" },
        padding: {
          xs: sidebarIsOpen ? "32px 24px" : "0px",
          md: "32px 24px",
        },
        height: "100%",
        position: "fixed",
        zIndex: 1,
        top: 0,
        left: 0,
        overflowX: "auto",
        paddingTop: "20px",
        boxShadow: {
          xs: "0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)",
        },
        display: {
          xs: !sidebarIsOpen ? "none" : "flex",
          md: "flex;",
        },
      }}
      justifyContent="space-between"
      style={{
        overflowY: "auto",
      }}
      borderRadius="0 24px 24px 0"
    >
      <HStack height="auto" justifyContent="center" alignItems="center">
        <img
          src={sidebarIsOpen ? tritophiaPurple : tritophiaWithoutText}
          height={sidebarIsOpen ? 90 : 70}
          onClick={setSidebar}
          alt="logo"
          style={{
            transition: "height 200ms linear",
          }}
        />
        {sidebarIsOpen && (
          <IconButton sx={{ width: 40 }} onClick={setSidebar}>
            {closeSideBarIcon}
          </IconButton>
        )}
      </HStack>

      <VStack spacing="6px" height="auto" style={{ flex: 1 }}>
        {menu.items
          .filter((val) => val.userType === userType)
          .map((menuItem: any, index: any) => (
            <VStack height="auto" width="100%">
              <HStack
                key={index}
                className={styles.sidebarItemContainer}
                height="24px"
                spacing="16px"
                width="-webkit-fill-available"
                style={{
                  padding: "8px",
                  paddingInlineStart: "16px",
                  cursor: "pointer",
                  background:
                    isButtonActive(menuItem) ||
                    menu.items.find((x) => menuItem.active)
                      ? "#8f52eb44"
                      : "",
                }}
                borderRadius="16px"
                onClick={() => handleRouteClick(menuItem.route)}
              >
                <Center width="16px" height="16px" style={{ minWidth: "16px" }}>
                  <>{changeIconActive(menuItem)}</>
                </Center>
                {sidebarIsOpen && (
                  <HStack justifyContent="space-between">
                    {menuItem.items.length > 0 ? (
                      <HStack>
                        <p
                          style={{
                            textDecoration: "none",
                            color: "rgb(29, 25, 41)",
                            fontSize: "14px",
                            fontWeight: "bold",
                            width: "100%",
                          }}
                        >
                          {menuItem.name}
                        </p>
                      </HStack>
                    ) : (
                      <p
                        style={{
                          textDecoration: "none",
                          color: "#1D1929",
                          fontSize: "14px",
                          fontWeight: "bold",
                          width: "100%",
                          userSelect: "none",
                        }}
                      >
                        {t(menuItem.name)}
                      </p>
                    )}

                    {menuItem.items.length > 0 ? (
                      <IconButton
                        sx={{ alignSelf: "center" }}
                        onClick={() => isOpenMenuItem(menuItem)}
                      >
                        {menuItem.active ? (
                          <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 9.5L7 6.5L4 9.5"
                              stroke="#1D1929"
                              stroke-linecap="square"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 6.5L7 9.5L10 6.5"
                              stroke="#1D1929"
                              stroke-linecap="square"
                            />
                          </svg>
                        )}
                      </IconButton>
                    ) : null}
                  </HStack>
                )}
              </HStack>
              {menuItem.items.length > 0 &&
                menuItem.active &&
                sidebarIsOpen && (
                  <VStack
                    height="auto"
                    spacing="16px"
                    width="auto"
                    style={{
                      paddingBlockStart: "8px",
                      paddingInlineStart: "48px",
                    }}
                  >
                    {menuItem.items.map((subMenu: any, index: any) => (
                      <Link
                        to={subMenu.route}
                        className={styles.sidebarIconBtn}
                        style={{
                          textDecoration: "none",
                          color: isButtonActive(subMenu)
                            ? "#8A24EA"
                            : "#1D1929",
                          fontSize: "14px",
                        }}
                      >
                        {subMenu.name}
                      </Link>
                    ))}
                  </VStack>
                )}
            </VStack>
          ))}
      </VStack>

      <HStack
        height="auto"
        sx={{
          pb: { xs: 5, md: 7, lg: 8 },
          justifyContent: sidebarIsOpen ? "space-between" : "center",
        }}
        spacing="12px"
      >
        <Box sx={{ border: "1px solid #e0e0e0", borderRadius: 26 }}>
          <IconButton
            sx={{
              backgroundColor:
                i18n.language === "tr" ? Color.TritophiaPurple : Color.White,
            }}
            onClick={() => changeLanguage("tr")}
          >
            <Typography
              variant="button"
              sx={{
                color:
                  i18n.language === "tr" ? Color.White : Color.TritophiaPurple,
              }}
            >
              TR
            </Typography>
          </IconButton>
          <IconButton
            sx={{
              backgroundColor:
                i18n.language === "en" ? Color.TritophiaPurple : Color.White,
            }}
            onClick={() => changeLanguage("en")}
          >
            <Typography
              variant="button"
              sx={{
                color:
                  i18n.language === "en" ? Color.White : Color.TritophiaPurple,
              }}
            >
              EN
            </Typography>
          </IconButton>
        </Box>
        <IconButton onClick={logoutClick}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5999 0.900001L7.89995 0.900001V0.700001L15.3 0.700001L15.3 15.3H7.89995V15.1H14.6H15.1V14.6L15.1 1.4V0.900001L14.5999 0.900001ZM11.7386 7.99723L8.41257 11.6004L8.26561 11.4648L10.597 8.93914L11.3716 8.1H10.2296L0.699951 8.1V7.9L10.1514 7.9H11.3585L10.505 7.04645L8.28279 4.82426L8.42421 4.68284L11.7386 7.99723Z"
              fill={Color.TritophiaPurple}
              stroke={Color.TritophiaPurple}
            />
          </svg>
        </IconButton>
      </HStack>
    </VStack>
  );
};

const mapStateToProps = (state?: RootState): ISidebar => {
  return {
    sidebarIsOpen: state?.sidebar.isOpen,
  };
};

export default connect(mapStateToProps)(Sidebar);
