import { FC } from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../Helpers/AuthenticationHelper";

interface IProtectedRoute {
  children?: any;
  permission?: string;
}

const ProtectedRoute: FC<IProtectedRoute> = ({ children, permission }) => {
  if (getToken()) {
    return children;
  } else {
    return <Navigate to="/account/login" />;
  }

  //Scenerio with permission

  // const session = useAppSelector((state) => state.session);
  // const userPermissions: any = useAppSelector(
  //   (state) => state.customUserConfiguration?.data?.auth
  // );

  // const permissionsCheck =
  //   permission !== undefined
  //     ? Object.entries(userPermissions.grantedPermissions).some(
  //         (val: any) => val[0] === permission
  //       )
  //     : true;

  // if (session?.data?.user && getToken()) {
  //   if (permissionsCheck) {
  //     return children;
  //   } else {
  //     if (session?.data?.tenant?.id) {
  //       return <Navigate to="/app/my-inbox" />;
  //     } else {
  //       return <Navigate to="/app/admin/host-settings" />;
  //     }
  //   }
  // } else {
  //   return <Navigate to="/account/login" />;
  // }
};

export default ProtectedRoute;
