import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IntegrationsGetByUserIdAction,
  IntegrationsGetFromAuthAction,
} from "../../../Actions/Shared/Integrations/IntegrationsActions";
import { IIntegrationsState } from "./interface";
import { Integrations } from "../../../DTOs/Integrations/integrations.dto";

const initialState: IIntegrationsState = {
  integrations: [],
  integration: new Integrations(),
  count: 0,
  successMessage: "",
  errorMessage: "",
  isDataLoaded: false,
};

const IntegrationsSlice = createSlice({
  name: "Integrationslice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(IntegrationsGetFromAuthAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      IntegrationsGetFromAuthAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.integration = action.payload;
      }
    );
    builder.addCase(
      IntegrationsGetFromAuthAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(IntegrationsGetByUserIdAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      IntegrationsGetByUserIdAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.integration = action.payload;
      }
    );
    builder.addCase(
      IntegrationsGetByUserIdAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
  },
});

export default IntegrationsSlice.reducer;
