import React, { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import "./VStack.css";
import { SxProps, Theme } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";

export interface VStackProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  spacing?: string;
  width?: string;
  height?: string;
  bg?: string;
  borderRadius?: string;
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  sx?: SxProps<Theme> | undefined;
}

const VStackComponent = styled.div<VStackProps>`
  gap: ${(props) => props.spacing};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
  border-radius: ${(props) => props.borderRadius};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

// { children, className, spacing = "0px", width = "100%", height = "100%", bg = "none", borderRadius = "0px", justifyContent = "flex-start", ...props}

const TritophiaVStack: FC<VStackProps> = ({
  children,
  className,
  spacing = "0px",
  width = "100%",
  height = "100%",
  bg = "none",
  borderRadius = "0px",
  justifyContent = "flex-start",
  alignItems = "flex-start",
  sx,
  ...props
}) => {
  return (
    <VStackComponent
      spacing={spacing}
      width={width}
      height={height}
      bg={bg}
      justifyContent={justifyContent}
      borderRadius={borderRadius}
      alignItems={alignItems}
      className={`tritophia-vstack${
        className !== undefined ? " " + className : ""
      }`}
      sx={sx}
      {...props}
    >
      {children}
    </VStackComponent>
  );
};

const VStack = MuiStyled(TritophiaVStack)();

export default VStack;
