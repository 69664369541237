import { DurationType } from "../../Enums/DurationType";
import {
  IUserEditionInformations,
  IUserEditionInformationsGetNewAmount,
} from "../../Interfaces/UserEditionInformations/userEditionInformations.interface";

export class UserEditionInformations implements IUserEditionInformations {
  id: number | undefined;
  expireDate: string | undefined;
  extendedExpireDate: string | undefined;
  editionId: number | undefined;
  editionName: string | undefined;
  editionDisplayName: string | undefined;
  editionProductCount: number | undefined;
  editionStorage: number | undefined;
  usedProductCount: number | undefined;
  usedModelCount: number | undefined;
  usedModelSizeMb: number | undefined;
  monthlyPrice: number | undefined;
  annualPrice: number | undefined;
  currencyId: number | undefined;
  userProfileId: number | undefined;

  constructor(
    id: number | undefined = undefined,
    expireDate: string = "",
    extendedExpireDate: string = "",
    editionId: number | undefined = undefined,
    editionName: string = "",
    editionDisplayName: string = "",
    editionProductCount: number | undefined = undefined,
    editionStorage: number | undefined = undefined,
    usedProductCount: number | undefined = undefined,
    usedModelCount: number | undefined = undefined,
    usedModelSizeMb: number | undefined = undefined,
    monthlyPrice: number | undefined = undefined,
    annualPrice: number | undefined = undefined,
    currencyId: number | undefined = undefined,
    userProfileId: number | undefined = undefined
  ) {
    this.id = id;
    this.expireDate = expireDate;
    this.extendedExpireDate = extendedExpireDate;
    this.editionId = editionId;
    this.editionName = editionName;
    this.editionDisplayName = editionDisplayName;
    this.editionProductCount = editionProductCount;
    this.editionStorage = editionStorage;
    this.usedProductCount = usedProductCount;
    this.usedModelCount = usedModelCount;
    this.usedModelSizeMb = usedModelSizeMb;
    this.monthlyPrice = monthlyPrice;
    this.annualPrice = annualPrice;
    this.currencyId = currencyId;
    this.userProfileId = userProfileId;
  }
}

export class UserEditionInformationsGetNewAmount
  implements IUserEditionInformationsGetNewAmount
{
  editionIdToUpgrade: number | undefined;
  userProfileId: number | undefined;
  currencyId: number | undefined;
  durationType: DurationType | undefined;

  constructor(
    editionIdToUpgrade: number | undefined = undefined,
    userProfileId: number | undefined = undefined,
    currencyId: number | undefined = undefined,
    durationType: DurationType | undefined = undefined
  ) {
    this.editionIdToUpgrade = editionIdToUpgrade;
    this.userProfileId = userProfileId;
    this.currencyId = currencyId;
    this.durationType = durationType;
  }
}
