import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "../Pages/Account/Login/Login";
import Loadable from "../Utils/Loadable";
import GuestRoute from "./GuestRoute";
import ProtectedRoute from "./ProtectedRoute";
import ContentLayout from "../Layouts/ContentLayout/ContentLayout";

const Layout = lazy(() => import("../Layouts/Layout"));

//#region Customer Pages Import
const CustomerMain = Loadable(
  lazy(() => import("../Pages/Customer/Main/Main"))
);

const Products = Loadable(
  lazy(() => import("../Pages/Customer/Products/Products"))
);

const ThreeDModels = Loadable(
  lazy(() => import("../Pages/Customer/ThreeDModels/ThreeDModels"))
);

const Profile = Loadable(
  lazy(() => import("../Pages/Customer/Profile/Profile"))
);

const Integration = Loadable(
  lazy(() => import("../Pages/Customer/Integration/Integration"))
);

const Edition = Loadable(
  lazy(() => import("../Pages/Customer/Edition/Edition/Edition"))
);

const ChangePassword = Loadable(
  lazy(() => import("../Pages/Customer/Profile/ChangePassword/ChangePassword"))
);

const PaymentHistory = Loadable(
  lazy(() => import("../Pages/Customer/Edition/PaymentHistory/PaymentHistory"))
);
//#endregion

//#region Admin Pages Import
const Customers = Loadable(
  lazy(() => import("../Pages/Admin/Customers/Customers"))
);

const CreateOrEditCustomer = Loadable(
  lazy(() => import("../Pages/Admin/Customers/CreateOrEditCustomer"))
);

const AdminMain = Loadable(lazy(() => import("../Pages/Admin/Main/Main")));

const Support = Loadable(lazy(() => import("../Pages/Admin/Support/Support")));

const Editions = Loadable(
  lazy(() => import("../Pages/Admin/Editions/Editions"))
);

const SiteLanguages = Loadable(
  lazy(() => import("../Pages/Admin/SiteLanguages/SiteLanguages"))
);

const AdminPaymentHistory = Loadable(
  lazy(() => import("../Pages/Admin/Customers/PaymentHistory/PaymentHistory"))
);

const AdminCustomerMain = Loadable(
  lazy(() => import("../Pages/Admin/Customers/Detail/Main/Main"))
);

const AdminCustomerProducts = Loadable(
  lazy(() => import("../Pages/Admin/Customers/Detail/Products/Products"))
);

const AdminCustomerThreeDModels = Loadable(
  lazy(
    () => import("../Pages/Admin/Customers/Detail/ThreeDModels/ThreeDModels")
  )
);

const AdminCustomerIntegration = Loadable(
  lazy(() => import("../Pages/Admin/Customers/Detail/Integration/Integration"))
);

const AdminCustomerEdition = Loadable(
  lazy(() => import("../Pages/Admin/Customers/Detail/Edition/Edition"))
);

const AdminCustomerProfile = Loadable(
  lazy(() => import("../Pages/Admin/Customers/Detail/Profile/Profile"))
);
//#endregion

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/account/login" />,
  },
  {
    path: "/customer",
    element: (
      <Suspense fallback={<></>}>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        path: "main",
        index: true,
        element: (
          <ProtectedRoute>
            <CustomerMain />
          </ProtectedRoute>
        ),
      },
      {
        path: "products/*",
        element: (
          <ProtectedRoute>
            <Products />
          </ProtectedRoute>
        ),
      },
      {
        path: "three-d-models/*",
        element: (
          <ProtectedRoute>
            <ThreeDModels />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            ),
          },
          {
            path: "change-password",
            element: (
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "integration",
        element: (
          <ProtectedRoute>
            <Integration />
          </ProtectedRoute>
        ),
      },
      {
        path: "edition",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Edition />
              </ProtectedRoute>
            ),
          },
          {
            path: "payment-history",
            element: (
              <ProtectedRoute>
                <PaymentHistory />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
    errorElement: <></>,
  },
  ///
  {
    path: "/admin",
    element: (
      <Suspense fallback={<></>}>
        <Layout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        path: "main",
        element: (
          <ProtectedRoute>
            <AdminMain />
          </ProtectedRoute>
        ),
      },
      {
        path: "customers",
        children: [
          {
            element: (
              <ProtectedRoute>
                <Customers />
              </ProtectedRoute>
            ),
            index: true,
          },
          {
            path: "create-or-edit",
            element: (
              <ProtectedRoute>
                <CreateOrEditCustomer />
              </ProtectedRoute>
            ),
          },
          {
            path: "create-or-edit:id",
            element: (
              <ProtectedRoute>
                <CreateOrEditCustomer />
              </ProtectedRoute>
            ),
          },
          {
            path: "payment-history/:id",
            element: (
              <ProtectedRoute>
                <AdminPaymentHistory />
              </ProtectedRoute>
            ),
          },
          {
            path: "detail/:id",
            element: (
              <Suspense fallback={<></>}>
                <ContentLayout />
              </Suspense>
            ),
            children: [
              {
                path: "main",
                index: true,
                element: (
                  <ProtectedRoute>
                    <AdminCustomerMain />
                  </ProtectedRoute>
                ),
              },
              {
                path: "products/*",
                element: (
                  <ProtectedRoute>
                    <AdminCustomerProducts />
                  </ProtectedRoute>
                ),
              },
              {
                path: "three-d-models/*",
                element: (
                  <ProtectedRoute>
                    <AdminCustomerThreeDModels />
                  </ProtectedRoute>
                ),
              },
              {
                path: "profile",
                element: (
                  <ProtectedRoute>
                    <AdminCustomerProfile />
                  </ProtectedRoute>
                ),
              },
              {
                path: "integration",
                element: (
                  <ProtectedRoute>
                    <AdminCustomerIntegration />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edition",
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute>
                        <AdminCustomerEdition />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: "payment-history",
                    element: (
                      <ProtectedRoute>
                        <PaymentHistory />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "editions",
        element: (
          <ProtectedRoute>
            <Editions />
          </ProtectedRoute>
        ),
      },
      {
        path: "support",
        element: (
          <ProtectedRoute>
            <Support />
          </ProtectedRoute>
        ),
      },
      {
        path: "site-languages",
        element: (
          <ProtectedRoute>
            <SiteLanguages />
          </ProtectedRoute>
        ),
      },
    ],
    errorElement: <></>,
  },

  {
    path: "/account",
    children: [
      {
        index: true,
        path: "login",
        element: (
          <GuestRoute>
            <Login />
          </GuestRoute>
        ),
        //
      },
    ],
    errorElement: <></>,
  },
  {
    path: "*",
    element: <h1>Not Found</h1>,
  },
]);
