import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../../Helpers/AuthenticationHelper";
import { CallToBackend as callToBackend } from "../../../../Middlewares/CallToBackend";
import { EncodeUrl as encodeUrl } from "../../../../Utils/EncodeUrl";

export const CustomersGetAllAction = createAsyncThunk(
  "CustomersGetAllAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend(
      encodeUrl("api/Users", data),
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const CustomersGetFromAuthAction = createAsyncThunk(
  "CustomersGetFromAuthAction",
  async (_, { rejectWithValue }) => {
    return await callToBackend(
      "api/Users/GetFromAuth",
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const CustomersGetAction = createAsyncThunk(
  "CustomersGetAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/Users/" + id,
      "GET",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const CustomersCreateAction = createAsyncThunk(
  "CustomersCreateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend("api/Users", "POST", undefined, data, getToken())
      .then((res) => {
        return res?.data?.data.id;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const CustomersUpdateAction = createAsyncThunk(
  "CustomersUpdateAction",
  async (data: any, { rejectWithValue }) => {
    return await callToBackend("api/Users", "PUT", undefined, data, getToken())
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);

export const CustomersDeleteAction = createAsyncThunk(
  "CustomersDeleteAction",
  async (id: number, { rejectWithValue }) => {
    return await callToBackend(
      "api/Users/" + id,
      "DELETE",
      undefined,
      undefined,
      getToken()
    )
      .then((res) => {
        return { success: res?.statusText };
      })
      .catch((error) => {
        return rejectWithValue({ error: error });
      });
  }
);
