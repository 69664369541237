import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LanguagesCreateAction,
  LanguagesDeleteAction,
  LanguagesGetAllAction,
  LanguagesUpdateAction,
} from "../../../Actions/Customer/Languages/LanguagesActions";
import { ILanguagesState } from "./interface";

const initialState: ILanguagesState = {
  languages: [],
  count: 0,
  successMessage: "",
  errorMessage: "",
  isDataLoaded: false,
};

const LanguagesSlice = createSlice({
  name: "Languageslice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(LanguagesGetAllAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      LanguagesGetAllAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.languages = action.payload.items;
        state.count = action.payload.count;
      }
    );
    builder.addCase(
      LanguagesGetAllAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(LanguagesCreateAction.pending, (state, action) => {
      state.languages = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      LanguagesCreateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      LanguagesCreateAction.rejected,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(LanguagesUpdateAction.pending, (state, action) => {
      state.languages = [];
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      LanguagesUpdateAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      LanguagesUpdateAction.rejected,
      (state, action: PayloadAction<any>) => {
        // state.errorMessage = action.payload.error;
      }
    );
    builder.addCase(LanguagesDeleteAction.pending, (state, action) => {
      state.successMessage = "";
      state.errorMessage = "";
    });
    builder.addCase(
      LanguagesDeleteAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successMessage = action.payload.success;
      }
    );
    builder.addCase(
      LanguagesDeleteAction.rejected,
      (state, action: PayloadAction<any>) => {
        // state.errorMessage = action.payload.error;
      }
    );
  },
});

export default LanguagesSlice.reducer;
