import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DashboardsState } from "./interface";
import { DashboardsGetAdminDashboardInformationAction } from "../../../Actions/Admin/Dashboard/DashboardActions";

const initialState: DashboardsState = {
  dashboardData: {
    activeCompanyCount: 0,
    customersNotYetPaidCount: 0,
  },
  isDataLoaded: false,
};

const DashboardsSlice = createSlice({
  name: "DashboardsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DashboardsGetAdminDashboardInformationAction.pending,
      (state, action) => {
        state.isDataLoaded = false;
      }
    );
    builder.addCase(
      DashboardsGetAdminDashboardInformationAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
        state.dashboardData = action.payload;
      }
    );
    builder.addCase(
      DashboardsGetAdminDashboardInformationAction.rejected,
      (state, action: PayloadAction<any>) => {
        state.isDataLoaded = true;
      }
    );
  },
});

export default DashboardsSlice.reducer;
