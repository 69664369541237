import { Slide, Snackbar, Alert as MuiAlert, AlertTitle } from "@mui/material";
import { FC } from "react";
import { getCurrentLanguage } from "../Helpers/LanguageHelper";

export type AlertType = "success" | "warning" | "error";

interface IAlertProps {
  open: boolean;
  type: AlertType;
  handleClose?: () => void;
  content?: string;
}

function TransitionLeft(props: any) {
  return <Slide {...props} direction="left" />;
}

function getTypeTitle(type: AlertType) {
  switch (type) {
    case "success":
      return getCurrentLanguage() === "tr" ? "Başarılı" : "Success";
    case "warning":
      return getCurrentLanguage() === "tr" ? "Dikkat" : "Warning";
    case "error":
      return getCurrentLanguage() === "tr" ? "Hata" : "Error";
    default:
      break;
  }
}

const Alert: FC<IAlertProps> = ({ open, type, handleClose, content }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <MuiAlert severity={type} sx={{ minWidth: 300 }}>
        <AlertTitle>{getTypeTitle(type)}</AlertTitle>
        {content}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
